import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import cn from 'classnames';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {
  CALCULATE_ADJUST,
  CREATE_ADJUST,
  OPEN_ADJUST_MODAL
} from 'store/orders/orderAdjust/action';
import DialogActions from 'components/DialogActions/styles';
import DialogFields from 'components/DialogFields/styles';
import {FieldInput, FieldInputDollar} from 'routes/App/dashboard/styles';
import DialogField from 'components/DialogField/styles';
import {FieldLabel} from 'components/FieldLabel/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import CheckboxS2E from 'components/Checkbox';
import {twoDecimalsFormatter} from 'helpers/functionUtils/helpFunctions';
import {MAX_ADJUST_AMOUNT, MIN_ADJUST_AMOUNT} from 'helpers/constants/orders';
import OrderAdjustSchema from 'helpers/services/formValidationSchemas/OrderAdjustSchema';
import {
  OrderAdjustFooter,
  OrderAdjustFooterLine
} from 'routes/App/orders/OrderDetails/Adjust/styles';
import Loading from 'components/shared/Utils/Loading';

const AdjustOrderModal = ({isOpen, invoiceId, orderId}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [salesTaxValue, setSalesTaxValue] = useState(0);
  const [alcoholTaxValue, setAlcoholTaxValue] = useState(0);
  const {calculationResults, loading} = useSelector(
    (state) => state.orderAdjustReducer
  );
  const {TaxPercents, AlcoholTax, _id: locationId} = useSelector(
    (state) => state.user.activeLocation
  );
  useEffect(() => {
    if (TaxPercents && TaxPercents.length) {
      setSalesTaxValue(TaxPercents.reduce((sum, item) => sum + item.Value, 0));
    }
    if (AlcoholTax) {
      setAlcoholTaxValue(Number(AlcoholTax));
    }
  }, [locationId]);
  const initialValues = {
    amount: null,
    reason: '',
    salesTax: true,
    alcoholTax: false
  };

  const reasons = [
    'Add item',
    'Add modifier',
    'Add tip',
    'Add service charge',
    'Other'
  ];

  const calculateAdjust = (values) => {
    if (
      !values.amount ||
      values.amount < MIN_ADJUST_AMOUNT ||
      values.amount > MAX_ADJUST_AMOUNT
    ) {
      return;
    }
    const body = {
      amount: values.amount
    };
    if (values.salesTax) {
      body.salesTaxRate = salesTaxValue;
    }
    if (values.alcoholTax) {
      body.alcoholTaxRate = alcoholTaxValue;
    }

    dispatch(CALCULATE_ADJUST({data: body, invoiceId}));
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={OrderAdjustSchema}
      onSubmit={(values, {resetForm}) => {
        const body = {
          reason: values.reason,
          amount: values.amount
        };
        if (values.salesTax) {
          body.salesTaxRate = salesTaxValue;
        }
        if (values.alcoholTax) {
          body.alcoholTaxRate = alcoholTaxValue;
        }
        dispatch(CREATE_ADJUST({data: body, invoiceId, orderId}));
      }}
    >
      {({values, errors, touched, handleChange, setFieldValue, submitForm}) => {
        return (
          <Dialog open={isOpen}>
            <DialogContainer className='DialogMd'>
              <DialogTitle className='DialogTitleMarginBottom'>
                Adjust price
              </DialogTitle>
              <DialogBody>
                <Form>
                  <DialogFields className='wAuto'>
                    <DialogField className='w40'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.amount && touched.amount && 'error'
                        })}
                      >
                        Amount to add
                      </FieldLabel>
                      <FieldInputDollar>
                        <FieldInput
                          onChange={(e) => {
                            const value = twoDecimalsFormatter(
                              e.target.value,
                              values.amount,
                              9999.99,
                              true
                            );
                            setFieldValue('amount', value);
                            calculateAdjust({
                              amount: value,
                              alcoholTax: values.alcoholTax,
                              salesTax: values.salesTax
                            });
                          }}
                          value={values.amount}
                          name='amount'
                          className={cn({
                            error: errors.amount && touched.amount
                          })}
                        />
                      </FieldInputDollar>
                      {errors.amount && touched?.amount ? (
                        <ErrorSignWrapper>
                          <ErrorSign>{errors.amount}</ErrorSign>
                        </ErrorSignWrapper>
                      ) : null}
                    </DialogField>

                    <DialogField className='w60 Say2EatSelect'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.reason && touched.reason && 'error'
                        })}
                      >
                        Select reason
                      </FieldLabel>
                      <FormControl>
                        <Select
                          inputProps={{'aria-label': 'value'}}
                          onChange={handleChange}
                          value={values.reason}
                          name='reason'
                          className={cn('', {
                            error: errors.reason && touched.reason && 'error'
                          })}
                        >
                          {reasons.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errors.reason && touched?.reason ? (
                        <ErrorSignWrapper>
                          <ErrorSign>{errors.reason}</ErrorSign>
                        </ErrorSignWrapper>
                      ) : null}
                    </DialogField>
                  </DialogFields>
                  <DialogFields className='wAuto'>
                    <DialogField className='w20'>
                      <FormControl>
                        <CheckboxS2E
                          onChange={(e) => {
                            setFieldValue('salesTax', e.target.checked);
                            calculateAdjust({
                              amount: values.amount,
                              alcoholTax: values.alcoholTax,
                              salesTax: e.target.checked
                            });
                          }}
                          name='salesTax'
                          checked={values?.salesTax}
                          label={`Sales Tax (${salesTaxValue}%)`}
                        />
                      </FormControl>
                    </DialogField>
                    <DialogField className='w20'>
                      <FormControl>
                        <CheckboxS2E
                          onChange={(e) => {
                            setFieldValue('alcoholTax', e.target.checked);
                            calculateAdjust({
                              amount: values.amount,
                              alcoholTax: e.target.checked,
                              salesTax: values.salesTax
                            });
                          }}
                          name='alcoholTax'
                          checked={values?.alcoholTax}
                          label={`Alcohol Tax (${alcoholTaxValue}%)`}
                        />
                      </FormControl>
                    </DialogField>
                  </DialogFields>
                  <OrderAdjustFooter>
                    <OrderAdjustFooterLine>
                      <div>Tax amount</div>
                      <div>
                        ${' '}
                        {new Intl.NumberFormat('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }).format(calculationResults?.taxes || 0)}
                      </div>
                    </OrderAdjustFooterLine>
                    <OrderAdjustFooterLine className='bold'>
                      <div>Total adjustment</div>
                      <div>
                        ${' '}
                        {new Intl.NumberFormat('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }).format(calculationResults?.totalAmount || 0)}
                      </div>
                    </OrderAdjustFooterLine>
                  </OrderAdjustFooter>
                  <DialogActions className='justifyCenter'>
                    <DialogAction className='w40'>
                      <ButtonOutline
                        onClick={() => dispatch(OPEN_ADJUST_MODAL(false))}
                      >
                        <span>Cancel</span>
                      </ButtonOutline>
                    </DialogAction>
                    <DialogAction className='w40'>
                      <ButtonPrimary type='button' onClick={submitForm}>
                        <span>Update</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </Form>
              </DialogBody>
            </DialogContainer>
            {loading && <Loading className='AdjustModal' />}
          </Dialog>
        );
      }}
    </Formik>
  );
};

AdjustOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired
};

export default AdjustOrderModal;
