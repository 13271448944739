import {createReducer} from 'redux-act';
import {
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  GET_DISPATCH_INFO,
  GET_DISPATCH_INFO_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  ORDER_UNMOUNT
} from './action';
import {ORDER} from '../../../helpers/constants/orders';
import {calculateOverdueAndFuture} from '../../../helpers/functionUtils/ordersUtils';

const initialState = () => ({
  order: {
    orderInfo: {
      items: []
    }
  },
  dispatchInfo: null,
  locationData: null,
  ordersLoading: false,
  changeStatusLoading: null
});

const orderDetailsReducer = createReducer(
  {
    [CHANGE_STATUS]: (state) => ({
      ...state,
      changeStatusLoading: true
    }),
    [CHANGE_STATUS_SUCCESS]: (state, payload) => ({
      ...state,
      order: {...state.order, [ORDER.ORDER_STATUS]: payload},
      changeStatusLoading: false
    }),
    [GET_ORDER]: (state) => ({
      ...state,
      loading: true
    }),
    [ORDER_UNMOUNT]: (state) => ({
      ...state,
      ...initialState(),
      currentLocationTimezone: state.currentLocationTimezone
    }),
    [GET_ORDER_SUCCESS]: (state, payload) => {
      return {
        ...state,
        order: calculateOverdueAndFuture(payload),
        loading: false
      };
    },
    [GET_DISPATCH_INFO]: (state) => ({
      ...state
    }),
    [GET_DISPATCH_INFO_SUCCESS]: (state, {support_number, order_id}) => {
      const info = {};
      info.DeliverySupportPhoneNumber = support_number;
      info.DeliveryReferenceNumber = order_id;
      return {
        ...state,
        dispatchInfo: info
      };
    }
  },
  initialState()
);

export default orderDetailsReducer;
