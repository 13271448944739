import {toast} from 'react-toastify';
import {put, takeEvery} from 'redux-saga/effects';
import {
  getLocationsPhoneOrderingApi,
  toggleAllLocationsPhoneOrderingApi,
  updatePhoneOrderingApi,
  togglePhoneOrderingApi
} from 'helpers/services/api/phoneOrdering';
import {
  GET_LOCATIONS_PHONE_ORDERING,
  GET_LOCATIONS_PHONE_ORDERING_SUCCESS,
  GET_LOCATIONS_PHONE_ORDERING_FAIL,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL,
  UPDATE_PHONE_ORDERING,
  UPDATE_PHONE_ORDERING_SUCCESS,
  UPDATE_PHONE_ORDERING_FAIL,
  TOGGLE_PHONE_ORDERING,
  TOGGLE_PHONE_ORDERING_SUCCESS,
  TOGGLE_PHONE_ORDERING_FAIL
} from './action';

function* getLocationsPhoneOrdering() {
  try {
    const {data} = yield getLocationsPhoneOrderingApi();

    if (data) {
      yield put(GET_LOCATIONS_PHONE_ORDERING_SUCCESS(data));
    } else {
      yield put(GET_LOCATIONS_PHONE_ORDERING_FAIL());
    }
  } catch (error) {
    yield put(GET_LOCATIONS_PHONE_ORDERING_FAIL());
  }
}

/**
 * Toggle all locations phone ordering
 * @param state {boolean}
 */
const toggleAllLocationsPhoneOrdering = function* ({payload}) {
  try {
    const response = yield toggleAllLocationsPhoneOrderingApi(payload.state);

    if (response) {
      yield put(TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS());
      toast.success(
        `Phone ordering has been ${
          payload.state ? 'enabled' : 'disabled'
        } for all locations!`
      );

      // Refresh locations
      yield put(GET_LOCATIONS_PHONE_ORDERING());
    } else {
      yield put(TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL());
    }
  } catch (error) {
    yield put(TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL());
  }
};

/**
 * @typedef {Object} UpdatePhoneOrderingPayload
 * @property {string} id
 * @property {phoneOrderingWebsiteUrl: string} data
 */

/**
 * Update phone ordering
 * @param payload {UpdatePhoneOrderingPayload}
 */
const updatePhoneOrdering = function* ({payload}) {
  try {
    const {data} = yield updatePhoneOrderingApi(payload.id, payload.data);

    if (data) {
      yield put(UPDATE_PHONE_ORDERING_SUCCESS());

      toast.success('Phone ordering has been updated!');

      // Refresh locations
      yield put(GET_LOCATIONS_PHONE_ORDERING());
    } else {
      yield put(UPDATE_PHONE_ORDERING_FAIL());
    }
  } catch (error) {
    yield put(UPDATE_PHONE_ORDERING_FAIL());
  }
};

/**
 * Toggle phone ordering
 * @param payload {payload: {locationId: string, state: boolean }}
 */
const togglePhoneOrdering = function* ({payload}) {
  try {
    const response = yield togglePhoneOrderingApi(
      payload.locationId,
      payload.state
    );

    if (response) {
      yield put(TOGGLE_PHONE_ORDERING_SUCCESS());
      toast.success(
        `Phone ordering has been ${payload.state ? 'enabled' : 'disabled'}!`
      );

      // Refresh locations
      yield put(GET_LOCATIONS_PHONE_ORDERING());
    } else {
      yield put(TOGGLE_PHONE_ORDERING_FAIL());
    }
  } catch (error) {
    yield put(TOGGLE_PHONE_ORDERING_FAIL());
  }
};

function* featureManagementSaga() {
  yield takeEvery(GET_LOCATIONS_PHONE_ORDERING, getLocationsPhoneOrdering);
  yield takeEvery(
    TOGGLE_ALL_LOCATIONS_PHONE_ORDERING,
    toggleAllLocationsPhoneOrdering
  );
  yield takeEvery(UPDATE_PHONE_ORDERING, updatePhoneOrdering);
  yield takeEvery(TOGGLE_PHONE_ORDERING, togglePhoneOrdering);
}

export default featureManagementSaga;
