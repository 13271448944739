import React from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toaster.css';

const Toaster = () => {
  return (
    <ToastContainer
      position='top-right'
      autoClose={4000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={4}
    />
  );
};

export default Toaster;
