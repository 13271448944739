import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {
  BOARD_V2_STATUS,
  BOARD_V2,
  BOARD_V2_STATUS_NAME
} from 'helpers/constants/adminRealTImeBoard';
import {FlexContainer} from 'components/layout';
import TableComponent from 'components/TableComponent';
import {
  OrderAlertsContainer,
  OrdersTableWrapper
} from 'routes/App/Managers/realTimeBoardV2/BoardTable/styles';
import {ButtonOutline} from 'components/buttons';
import {
  ActualLateComponent,
  CanceledByDeliveryComponent,
  CanceledByRestComponent,
  CanceledBySauceComponent,
  CateringComponent,
  IconBookADriverComponent,
  FutureOrderAlertComponent,
  LargeOrderComponent,
  LongDeliveryDistanceComponent,
  NewRestComponent,
  PotentialLateComponent,
  RestClosedComponent,
  TierRestaurantComponent,
  TipsComponent,
  UnassignedFewTimesComponent,
  NewDispatchComponent
} from 'routes/App/Managers/realTimeBoardV2/BoardTable/helpersAlerts';
import {
  OPEN_ADDITION_INFO_MODAL,
  OPEN_ACTIONS_MADE_MODAL,
  RDB_REMOVE_ORDER_MODAL
} from 'store/realTimeBoardV2/action';
import ButtonMore from 'routes/App/merchants/components/ButtonMore';
import {
  findLastOfColorAndUpdate,
  checkIsFutureOrder
} from 'routes/App/Managers/realTimeBoardV2/helpers';

const formatTime = (date, timezone = 0) => {
  const resultDate =
    date?.includes('Z') || date?.includes('+00:00') ? date : `${date}Z`;
  return moment(resultDate)
    .utc()
    .utcOffset(Number(timezone) || 0)
    .format('hh:mm A');
};

const formatRestaurantName = (name) => {
  let result;
  if (name) {
    result = name?.toLowerCase().split(' ').join('-');
  }
  return result;
};

const getRestName = (order) => {
  if (order[BOARD_V2.MERCHANT]?.name) {
    if (
      order[BOARD_V2.MERCHANT]?.locationsCount > 1 &&
      order[BOARD_V2.LOCATION]?.name
    ) {
      return (
        <>
          <p>{order[BOARD_V2.MERCHANT]?.name?.trim()}</p>
          <p>{`(${order[BOARD_V2.LOCATION]?.name?.trim()})`}</p>
        </>
      );
    }
    return order[BOARD_V2.MERCHANT]?.name;
  }
  return order[BOARD_V2.RESTAURANT_NAME];
};
const getRestNameTitle = (order) => {
  if (order[BOARD_V2.MERCHANT]?.name) {
    if (
      order[BOARD_V2.MERCHANT]?.locationsCount > 1 &&
      order[BOARD_V2.LOCATION]?.name
    ) {
      return `${order[BOARD_V2.MERCHANT]?.name?.trim()} (${order[
        BOARD_V2.LOCATION
      ]?.name?.trim()})`;
    }
    return order[BOARD_V2.MERCHANT]?.name;
  }
  return order[BOARD_V2.RESTAURANT_NAME];
};

const diffTime = (promisedTime, actualTime) => {
  let diff = moment
    .duration(moment(promisedTime).diff(moment(actualTime)))
    .asMinutes()
    .toFixed(0);
  if (Number(diff) === 0) {
    diff = '0 min';
  } else if (diff.includes('-')) {
    diff = `${diff} min`;
  } else {
    diff = `+${diff} min`;
  }
  return (
    <>
      {diff && (
        <div
          className={cn('greenText', {
            redText: diff.includes('+')
          })}
        >
          {diff}
        </div>
      )}
    </>
  );
};

const checkAtThePickupStatus = (order) => {
  return (
    order[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ENROUTE &&
    order[BOARD_V2.COURIER_DISTANCE] <
      process.env.REACT_APP_RDB_COURIER_DISTANCE_TO_RESTAURANT
  );
};

const getFieldsConfig = (dispatch) => [
  {
    title: 'Order alerts',
    renderer: (data) => (
      <OrderAlertsContainer>
        {NewDispatchComponent(data)}
        {TierRestaurantComponent(data)}
        {PotentialLateComponent(data)}
        {ActualLateComponent(data)}
        {LargeOrderComponent(data)}
        {TipsComponent(data)}
        {RestClosedComponent(data)}
        {CanceledBySauceComponent(data)}
        {CanceledByRestComponent(data)}
        {CanceledByDeliveryComponent(data)}
        {CateringComponent(data)}
        {NewRestComponent(data)}
        {LongDeliveryDistanceComponent(data)}
        {UnassignedFewTimesComponent(data)}
        {IconBookADriverComponent(data)}
        {FutureOrderAlertComponent(data)}
      </OrderAlertsContainer>
    )
  },
  {
    title: 'Restaurant time',
    renderer: (data) => (
      <div className='TableCenter textUppercase'>
        {checkIsFutureOrder(data[BOARD_V2.PICKUP]?.eta)
          ? ''
          : moment()
              .utc()
              .utcOffset(Number(data[BOARD_V2.TIMEZONE]) || 0)
              .format('h:mm a')}
      </div>
    )
  },
  {
    title: 'Pickup time',
    fieldName: 'pickup_deadline',
    sortable: false,
    renderer: (data) => (
      <div className='TableHorizontalCenter'>
        {checkIsFutureOrder(data[BOARD_V2.PICKUP]?.eta) ? (
          ''
        ) : (
          <>
            <div className='wordWrap'>
              {formatTime(
                data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SEARCHING ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.ASSIGNED ||
                  data[BOARD_V2.STATUS]?.type ===
                    BOARD_V2_STATUS.PICKUP_ENROUTE ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ARRIVED
                  ? data[BOARD_V2.PICKUP]?.deadline
                  : data[BOARD_V2.PICKUP]?.eta,
                data[BOARD_V2.TIMEZONE]
              )}
            </div>
            {data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SEARCHING ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.ASSIGNED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ARRIVED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ENROUTE ? (
              <div className='wordWrap'>
                {diffTime(
                  data[BOARD_V2.PICKUP]?.eta,
                  data[BOARD_V2.PICKUP]?.deadline
                )}
              </div>
            ) : (
              <div className='wordWrap'>Actual</div>
            )}
          </>
        )}
      </div>
    )
  },
  {
    title: 'Dropoff time',
    fieldName: 'dropoff_deadline',
    sortable: false,
    renderer: (data) => (
      <div className='TableHorizontalCenter'>
        {checkIsFutureOrder(data[BOARD_V2.PICKUP]?.eta) ? (
          ''
        ) : (
          <>
            <div className='wordWrap'>
              {formatTime(
                data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SEARCHING ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.ASSIGNED ||
                  data[BOARD_V2.STATUS]?.type ===
                    BOARD_V2_STATUS.PICKUP_ENROUTE ||
                  data[BOARD_V2.STATUS]?.type ===
                    BOARD_V2_STATUS.PICKUP_ARRIVED ||
                  data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKED_UP ||
                  data[BOARD_V2.STATUS]?.type ===
                    BOARD_V2_STATUS.DROPOFF_ENROUTE ||
                  data[BOARD_V2.STATUS]?.type ===
                    BOARD_V2_STATUS.DROPOFF_ARRIVED
                  ? data[BOARD_V2.DROPOFF]?.deadline
                  : data[BOARD_V2.DROPOFF]?.eta,
                data[BOARD_V2.TIMEZONE]
              )}
            </div>
            {data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SEARCHING ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.ASSIGNED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ENROUTE ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKUP_ARRIVED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.PICKED_UP ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.DROPOFF_ARRIVED ||
            data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.DROPOFF_ENROUTE ? (
              <div className='wordWrap'>
                {diffTime(
                  data[BOARD_V2.DROPOFF]?.eta,
                  data[BOARD_V2.DROPOFF]?.deadline
                )}
              </div>
            ) : (
              <div className='wordWrap'>Actual</div>
            )}
          </>
        )}
      </div>
    )
  },
  {
    title: 'Status',
    sortable: false,
    fieldName: 'status',
    renderer: (data) => {
      return (
        <FlexContainer className='TableHorizontalCenter'>
          {BOARD_V2_STATUS_NAME[data[BOARD_V2.STATUS]?.type]}
        </FlexContainer>
      );
    }
  },
  {
    title: 'Delivery company',
    fieldName: 'service_type',
    sortable: false,
    renderer: (data) => {
      return (
        <FlexContainer className='TableHorizontalCenter'>
          <div className='wordWrap'>
            {data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.FUTURE_ORDER ||
            !data[BOARD_V2.DELIVERY]?.provider
              ? 'N/A'
              : data[BOARD_V2.DELIVERY]?.provider}
          </div>
        </FlexContainer>
      );
    }
  },
  {
    title: 'Restaurant name',
    fieldName: 'restaurant_name',
    sortable: false,
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <div title={getRestNameTitle(data)} className='wordWrap restaurantName'>
          {getRestName(data)}
        </div>
      </FlexContainer>
    )
  },
  {
    title: 'Customer name',
    fieldName: 'customer_name',
    sortable: false,
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <div className='wordWrap customerName'>
          {data[BOARD_V2.CUSTOMER_NAME]}
        </div>
      </FlexContainer>
    )
  },
  {
    title: 'Tracking link',
    renderer: (data) => (
      <FlexContainer className='ButtonsCenter'>
        <ButtonOutline
          onClick={() => {
            window.open(data[BOARD_V2.RESTAURANT_TRACKING_LINK], '_blank');
          }}
          className={cn('ButtonSmallXs', {
            disabled: !data[BOARD_V2.RESTAURANT_TRACKING_LINK]
          })}
        >
          <b>R</b>
        </ButtonOutline>
        <ButtonOutline
          onClick={() => {
            window.open(data[BOARD_V2.USER_TRACKING_LINK], '_blank');
          }}
          className={cn('ButtonSmallXs', {
            disabled: !data[BOARD_V2.USER_TRACKING_LINK]
          })}
        >
          <b>U</b>
        </ButtonOutline>
      </FlexContainer>
    )
  },
  {
    title: 'Actions made',
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <ButtonOutline
          onClick={() => {
            dispatch(
              OPEN_ACTIONS_MADE_MODAL({open: true, id: data[BOARD_V2.ID]})
            );
          }}
          className='ButtonSmallXs RadiusMin paddingLeftRightMin'
        >
          <b>Report Action</b>
        </ButtonOutline>
      </FlexContainer>
    )
  },
  {
    title: '',
    renderer: (data) => (
      <FlexContainer className='justify-end align-center heightReset'>
        <ButtonMore
          itemsData={[
            {
              title: 'Additional info',
              action: () => {
                dispatch(
                  OPEN_ADDITION_INFO_MODAL({open: true, id: data[BOARD_V2.ID]})
                );
              }
            },
            {
              title: 'Remove order',
              action: () => {
                dispatch(
                  RDB_REMOVE_ORDER_MODAL({
                    open: true,
                    id: data[BOARD_V2.ID]
                  })
                );
              }
            }
          ]}
        />
      </FlexContainer>
    )
  }
];

const BoardTable = ({orders, loading}) => {
  const dispatch = useDispatch();
  const {userData} = useSelector((state) => state.user);

  return (
    <OrdersTableWrapper className={cn('OrdersTableWrapper')}>
      <TableComponent
        fields={getFieldsConfig(dispatch, false, userData)}
        data={findLastOfColorAndUpdate(orders)}
        loading={loading}
        rowKeyPath={BOARD_V2.ID}
        isRtbV2
      />
    </OrdersTableWrapper>
  );
};

BoardTable.propTypes = {
  orders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default BoardTable;
