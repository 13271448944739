import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import BoardTable from 'routes/App/Managers/realTimeBoardV2/BoardTable';
import {OrdersContainer} from 'routes/App/Managers/realTimeBoardV2/styles';
import Headline from 'components/Headline/styles';
import {
  RUN_ORDER_RTB_JOB,
  STOP_ORDER_RTB_JOB,
  RDB_REMOVE_ORDER_MODAL,
  REMOVE_ORDER
} from 'store/realTimeBoardV2/action';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import AdditionInfoModal from 'routes/App/Managers/realTimeBoardV2/Modals/AdditionalInfoModal';
import {DashboardHeaderActions} from 'components/DashboardHeaderActions/styles';
import ActionsMadeModal from 'routes/App/Managers/realTimeBoardV2/Modals/ActionsMadeModal';
import RemoveOrderModal from './Modals/RemoveOrderModal';

const RealTimeBoardV2 = () => {
  const dispatch = useDispatch();

  const {
    orders,
    loading,
    lastUpdateTime,
    openActionsModal,
    openAdditionInfoModal,
    removeOrderModal
  } = useSelector((state) => state.realTimeBoardV2Reducer);

  useEffect(() => {
    dispatch(STOP_ORDER_RTB_JOB());
    dispatch(RUN_ORDER_RTB_JOB());

    return () => {
      dispatch(STOP_ORDER_RTB_JOB());
    };
  }, []);
  return (
    <>
      <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
        <DashboardHeader className='realTimeBoardV2'>
          <div>
            <Headline>Real time delivery dashboard V2</Headline>
          </div>
          <DashboardHeaderActions>
            {lastUpdateTime && (
              <span className='redText'>
                Last update: {moment(lastUpdateTime).format('h:mm:ss a')}
              </span>
            )}
          </DashboardHeaderActions>
        </DashboardHeader>
        <OrdersContainer>
          <BoardTable orders={orders} loading={loading} />
        </OrdersContainer>
      </DashboardContent>
      {openAdditionInfoModal && <AdditionInfoModal />}
      {openActionsModal && <ActionsMadeModal />}

      <RemoveOrderModal
        isOpen={removeOrderModal.open}
        message={`Remove order ${removeOrderModal.id || ''} from the board?`}
        onCancel={() => dispatch(RDB_REMOVE_ORDER_MODAL({open: false}))}
        onOk={() => {
          dispatch(REMOVE_ORDER({id: removeOrderModal.id}));
          dispatch(RDB_REMOVE_ORDER_MODAL({open: false}));
        }}
      />
    </>
  );
};

export default RealTimeBoardV2;
