import {put, takeEvery, select} from 'redux-saga/effects';
import {updateOrderRefund} from 'helpers/services/api/orders';
import {
  MAKE_REFUND,
  OPEN_CANCELLATION_REFUND_MODAL
} from 'store/global/orders/CancellationRefund/action';
import {toast} from 'react-toastify';

export function* makeCancellationRefund({payload: {data, invoiceId}}) {
  try {
    const locationId = yield select((state) => state.user.locationId);

    const refund = yield updateOrderRefund(invoiceId, {...data, locationId});

    if (refund) {
      yield put(
        OPEN_CANCELLATION_REFUND_MODAL({
          isOpenModal: false,
          invoiceId: null,
          showCannotDeliveryText: false,
          loadingRefund: false
        })
      );
      toast.success(
        'The customer was refunded and a confirmation email was sent'
      );
    }
  } catch (error) {
    yield put(
      OPEN_CANCELLATION_REFUND_MODAL({
        isOpenModal: false,
        invoiceId: null,
        showCannotDeliveryText: false,
        loadingRefund: false
      })
    );
    console.log('SAGA ERROR', error);
  }
}

function* cancellationRefundSaga() {
  yield takeEvery(MAKE_REFUND, makeCancellationRefund);
}

export default cancellationRefundSaga;
