import styled from 'styled-components';
import AuthPhoto from 'assets/loginPhoto.webp';
import IconArrowButton from 'assets/icon-arrow-down-white.svg';
import IconHand from 'assets/icon-hand.svg';

export const AuthMainSection = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  @media (max-width: 1000px) {
    min-height: inherit;
    display: block;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    height: 100vh;

    &.isActive {
      .AuthFeedback {
        transform: translateY(0vh);
        .AuthFeedbackButton {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.isActive {
      .AuthFeedback {
        transform: translateY(0vh);
      }
    }
  }
`;

export const AuthFeedback = styled.div`
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
    position: absolute;
    top: 100vh;
    left: 0;
    transform: translateY(-100%);
    transition-property: transform;
    z-index: 4;
    transition-duration: 0.5s;
  }
`;
export const AuthFeedbackTop = styled.div`
  display: none;
  position: absolute;
  top: -13px;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 13px;
  background: #525f6b;
  @media (max-width: 1000px) {
    display: block;
  }
  @media (max-width: 767px) {
    height: 10px;
    top: -10px;
  }
`;
export const AuthFeedbackButton = styled.div`
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-56px);
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: #525f6b;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 88px;
    height: 88px;
    transform: translateX(-44px);
    top: -36px;
  }

  &::before {
    position: absolute;
    top: 18px;
    left: 34px;
    content: '';
    width: 15px;
    height: 27px;
    background-image: url(${IconArrowButton});
    background-repeat: no-repeat;
    background-size: contain;
    transition-property: transform;
    transition-duration: 0.3s;
    transform: rotate(0);
    @media (max-width: 767px) {
      left: 23px;
      top: 11px;
    }
  }
  &::after {
    position: absolute;
    top: 18px;
    right: 30px;
    content: '';
    width: 20px;
    height: 25px;
    background-image: url(${IconHand});
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 767px) {
      right: 24px;
      top: 11px;
    }
  }
`;

export const AuthFeedbackContainer = styled.div`
  height: 100vh;
  background-image: url(${AuthPhoto});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding: 0 116px 40px 116px;
  @media (max-width: 1300px) {
    padding: 0 56px 40px 56px;
  }
  @media (max-width: 1000px) {
    height: 50vh;
  }
`;

export const AuthReferContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 31px 20px 31px 40px;
  background-color: #fff;
  height: max-content;
  .ReferTitle {
    font-weight: bold;
    font-size: 26px;
  }
  .ReferText {
    margin-top: 10px;
    font-size: 18px;

    a {
      color: #ff2d38;
      font-weight: bold;
      text-decoration: none;
    }
    .RedText {
      color: #ff2d38;
    }
    .SmText {
      font-size: 10px;
    }
  }

  @media (max-width: 767px) {
    .ReferTitle {
      font-size: 18px;
    }
    .ReferText {
      font-size: 10px;

      .SmText {
        font-size: 6px;
      }
    }
  }
`;
export const AuthSection = styled.div`
  background-color: #fff;
  // min-height: 100vh;
  padding: 80px 20px 64px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1650px) {
    padding-top: 90px;
  }
  @media (max-width: 1440px) {
    padding: 80px 20px 55px 20px;
  }
  @media (max-width: 1300px) {
    padding: 50px 20px 40px 20px;
  }
  @media (max-width: 1000px) {
    padding-top: 100px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 65px 15px 0 15px;
  }
  @media (max-width: 440px) {
    padding: 45px 10px 0 10px;
    min-height: inherit;
  }
  &.Referral {
    width: 50%;
    @media (max-width: 1000px) {
      padding: 25px 0 50px 0;
      width: 100%;
      //  min-height: 80vh;
    }
    @media (max-width: 767px) {
      padding: 65px 0 50px 0;
      // min-height: 100vh;
    }
    @media (max-width: 440px) {
      padding: 45px 0;
      // min-height: 99vh;
    }
  }
`;
export const AuthLogo = styled.img`
  display: block;
  max-width: 326px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1800px) {
    max-width: 300px;
  }
  @media (max-width: 1700px) {
    max-width: 280px;
  }
  @media (max-width: 1500px) {
    max-width: 260px;
  }
  @media (max-width: 1400px) {
    max-width: 240px;
  }
  @media (max-width: 767px) {
    max-width: 210px;
  }
  @media (max-width: 440px) {
    max-width: 200px;
  }
`;
export const AuthForm = styled.div`
  display: block;
  max-width: 550px;
  width: 100%;
  margin: 45px auto 0 auto;
  background-color: #fff;
  //box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
  //  10px 10px 20px rgba(108, 49, 151, 0.06),
  //  30px 30px 30px rgba(108, 49, 151, 0.04);
  border-radius: 5px;
  padding: 0 38px 60px 38px;
  @media (max-width: 1700px) {
    margin-top: 35px;
  }
  @media (max-width: 1500px) {
    max-width: 530px;
    padding: 0 38px 50px 38px;
  }
  @media (max-width: 1400px) {
    padding: 0 38px 40px 38px;
    max-width: 510px;
  }
  @media (max-width: 1100px) {
    margin-top: 30px;
  }
  @media (max-width: 1000px) {
    margin-top: 50px;
  }
  @media (max-width: 560px) {
    box-shadow: none;
    padding: 0 25px 60px 25px;
    margin-top: 30px;
  }
  @media (max-width: 440px) {
    margin-top: 5px;
    padding: 0 20px 60px 20px;
  }
`;
export const AuthTitle = styled.div`
  display: block;
  text-align: left;
  margin-top: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2;
  color: #2f3d48;
  span {
    color: #ff2d38;
  }
  @media (max-width: 1650px) {
    font-size: 38px;
    margin-top: 55px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-top: 50px;
  }
  @media (max-width: 1300px) {
    font-size: 30px;
    margin-top: 40px;
  }
  @media (max-width: 1100px) {
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

export const AuthBottom = styled.div`
  display: block;

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 460px) {
    display: block;
    text-align: center;
    bottom: 30px;
    left: 0;
    width: 100%;
  }
  span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    color: #232d3a;
  }
`;
export const AuthLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  color: #232d3a;
  @media (max-width: 1100px) {
    margin: 2px 0 0 8px;
  }
  @media (max-width: 460px) {
    margin: 10px 0 0 0;
  }
`;
export const AuthLink = styled.a`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  color: #232d3a;
  margin: 0 4px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  &:hover {
    border-bottom-color: #232d3a;
  }
  @media (max-width: 1100px) {
    &:first-child {
      position: relative;
      margin-right: 8px;
      &::after {
        position: absolute;
        content: '';
        top: 1px;
        right: -6px;
        width: 2px;
        height: 13px;
        background-color: #232d3a;
      }
    }
  }
`;
