import {createAction} from 'redux-act';

export const GET_LOCATIONS_PHONE_ORDERING = createAction(
  'phoneOrdering/GET_LOCATIONS_PHONE_ORDERING'
);

export const GET_LOCATIONS_PHONE_ORDERING_SUCCESS = createAction(
  'phoneOrdering/GET_LOCATIONS_PHONE_ORDERING_SUCCESS'
);

export const GET_LOCATIONS_PHONE_ORDERING_FAIL = createAction(
  'phoneOrdering/GET_LOCATIONS_PHONE_ORDERING_FAIL'
);

export const TOGGLE_ALL_LOCATIONS_PHONE_ORDERING = createAction(
  'phoneOrdering/TOGGLE_ALL_LOCATIONS_PHONE_ORDERING'
);

export const TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS = createAction(
  'phoneOrdering/TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS'
);

export const TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL = createAction(
  'phoneOrdering/TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL'
);

export const UPDATE_PHONE_ORDERING = createAction(
  'phoneOrdering/UPDATE_PHONE_ORDERING'
);

export const UPDATE_PHONE_ORDERING_SUCCESS = createAction(
  'phoneOrdering/UPDATE_PHONE_ORDERING_SUCCESS'
);

export const UPDATE_PHONE_ORDERING_FAIL = createAction(
  'phoneOrdering/UPDATE_PHONE_ORDERING_FAIL'
);

export const TOGGLE_PHONE_ORDERING = createAction(
  'phoneOrdering/TOGGLE_PHONE_ORDERING'
);

export const TOGGLE_PHONE_ORDERING_SUCCESS = createAction(
  'phoneOrdering/TOGGLE_PHONE_ORDERING_SUCCESS'
);

export const TOGGLE_PHONE_ORDERING_FAIL = createAction(
  'phoneOrdering/TOGGLE_PHONE_ORDERING_FAIL'
);
