import React, {useState} from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Queue from './menuQueue/MenuQueue';
import Merchants from '../merchants';
import Sidebar from './components/Sidebar';
import Users from '../users';
import FeatureManagement from './FeatureManagement';
import RealTimeBoard from './realTimeBoard';
import Navbar from './Header/Navbar';
import RefundsCalculator from './RefundsCalculator';
import PhoneOrdering from './PhoneOrdering';
import InsightsControl from './InsightsControl';
import RealTimeBoardV2 from './realTimeBoardV2';
import FindDelivery from './FindDelivery';

const ADMIN_ROUTES = [
  '/managers/menu-queue',
  '/managers/feature-management',
  '/managers/real-time-board',
  '/managers/real-time-board-v2',
  '/managers/find-delivery',
  '/managers/users',
  '/managers/merchants/new',
  '/managers/merchants/active',
  '/managers/refunds-calculator',
  '/managers/phone-ordering',
  '/managers/insights-control'
];

const Managers = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const {AllowedFeatures} = useSelector((state) => state.user.userData);

  return (
    <>
      <Navbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Route path='/managers/merchants' component={Merchants} />
      <Route exact path='/managers/menu-queue' component={Queue} />
      <Route
        exact
        path='/managers/feature-management'
        component={FeatureManagement}
      />
      <Route exact path='/managers/real-time-board' component={RealTimeBoard} />
      <Route
        exact
        path='/managers/real-time-board-v2'
        component={RealTimeBoardV2}
      />
      <Route exact path='/managers/find-delivery' component={FindDelivery} />
      <Route exact path='/managers/users' component={Users} />
      {AllowedFeatures?.includes('refunds_calculator') && (
        <Route
          exact
          path='/managers/refunds-calculator'
          component={RefundsCalculator}
        />
      )}
      {AllowedFeatures?.includes('phone_ordering') && (
        <Route
          exact
          path='/managers/phone-ordering'
          component={PhoneOrdering}
        />
      )}
      {AllowedFeatures?.includes('insights_control') && (
        <Route
          exact
          path='/managers/insights-control'
          component={InsightsControl}
        />
      )}
      <Route
        path='/'
        render={() => {
          let path;
          if (
            (ADMIN_ROUTES.includes(location.pathname) &&
              !location.pathname.startsWith('/managers/refunds-calculator')) ||
            (ADMIN_ROUTES.includes(location.pathname) &&
              location.pathname.startsWith('/managers/refunds-calculator') &&
              AllowedFeatures?.includes('refunds_calculator'))
          ) {
            path = location.pathname;
          } else {
            path = '/managers/merchants/new';
          }
          return <Redirect to={path} />;
        }}
      />
    </>
  );
};

export default Managers;
