import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {
  BillContainer,
  BillEtaLabel,
  BillEtaValue,
  BillFutureOrderHead
} from '../../OrdersList/styles';
import DisplayField from '../../../../../components/fields/DisplayField';
import {Body1, Caption1, Caption2} from '../../../../../components/typography';
import {BillDottedLine} from '../../OrderDetails/styles';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import SubTitle from '../../../../../components/SubTitle/styles';
import {ORDER_TYPES, ORDER_TYPE} from '../../../../../helpers/constants/orders';
import {groupDigital} from '../../../../../helpers/functionUtils/groupByDigital';
import {phoneFormatForView} from '../../../../../helpers/functionUtils/helpFunctions';
import {ValueTitle} from './styles';
import {checkIsFutureOrder} from '../../../../../helpers/functionUtils/ordersUtils';

const mapOrderOptionsHelper = (
  item,
  {fromSecondLevel, quantity = 1} = {},
  useSalesPackage
) => (
  <>
    <Caption1 className='block '>{item.sectionName}</Caption1>
    <FlexContainer justifyContent='space-between'>
      <FlexItem className='wordWrap'>
        <Body1>
          {!fromSecondLevel && <b>{`${item.quantity} x `}</b>}
          {item.name}
        </Body1>
      </FlexItem>
      <FlexItem
        style={{
          width: '120px',
          paddingRight: '40px',
          paddingLeft: '10px',
          textAlign: 'right',
          alignSelf: 'self-end'
        }}
      >
        <Body1>
          {' '}
          {!useSalesPackage &&
            `$${groupDigital(
              Number(Number(item.pricePerUnit) || 0).toFixed(2)
            )}`}
        </Body1>
      </FlexItem>
    </FlexContainer>
    {item.itemOptions && item.itemOptions.length && (
      <div className='marginLeft2l'>
        {item.itemOptions.map(
          (option) =>
            option.items &&
            option.items.length &&
            option.items.map((optionItem) =>
              mapOrderOptionsHelper(
                optionItem,
                {
                  fromSecondLevel: true,
                  quantity: item.quantity * quantity
                },
                useSalesPackage
              )
            )
        )}
      </div>
    )}
    <div style={{paddingRight: '120px'}}>
      <Body1 className='wordWrap'>
        <i>{item.specialNotes && `(${item.specialNotes})`}</i>
      </Body1>
    </div>
  </>
);

const deepCount = (item) => {
  let total = Number(item.total || 0);

  if (item.itemOptions && item.itemOptions.length) {
    item.itemOptions.forEach(
      (option) =>
        option.items &&
        option.items.length &&
        option.items.forEach((optionItem) => {
          total += (item.quantity || 0) * deepCount(optionItem);
        })
    );
  }

  return total;
};

export default class Bill extends Component {
  render() {
    const {order, locationName, useSalesPackage} = this.props;
    const {
      orderInfo: {discounts = []}
    } = order;

    return (
      <BillContainer>
        {checkIsFutureOrder(order) && order.orderStatus !== 'Canceled' && (
          <BillFutureOrderHead className='marginBottomXs'>
            ***FUTURE ORDER***
          </BillFutureOrderHead>
        )}
        {order.orderStatus === 'Canceled' && (
          <BillFutureOrderHead className='marginBottomXs CanceledOrder'>
            ***CANCELED ORDER***
          </BillFutureOrderHead>
        )}
        <DisplayField
          label={<BillEtaLabel>ETA</BillEtaLabel>}
          value={
            checkIsFutureOrder(order) ? (
              <BillEtaValue>
                For {moment(order.date).utcOffset(0).format('MMMM D, YYYY')},{' '}
                <span>{moment(order.date).utcOffset(0).format('h:mm A')}</span>
              </BillEtaValue>
            ) : (
              <BillEtaValue>
                <span>
                  Today, {moment(order.date).utcOffset(0).format('h:mm A')}
                </span>
              </BillEtaValue>
            )
          }
          className='marginBottomXs widthAuto'
          spaceBetween
          flexItemFullWidth
        />
        <DisplayField
          label={<Body1>Order Type:</Body1>}
          value={
            <Caption1>
              {order.orderInfo.isCurbside &&
              order.orderInfo.deliveryType === 'PickUp'
                ? 'Curbside Pickup'
                : ORDER_TYPES[order.orderInfo.deliveryType]}
            </Caption1>
          }
          className='marginBottomXs'
          spaceBetween
        />
        {ORDER_TYPES[order.orderInfo.deliveryType] ===
          ORDER_TYPES[ORDER_TYPE.DELIVERY] && (
          <DisplayField
            label={<Body1>Delivery Address:</Body1>}
            value={<Caption1>{order.orderInfo.deliveryAddress}</Caption1>}
            className='marginBottomXs'
            spaceBetween
          />
        )}

        <BillDottedLine className='marginBottomXl withoutOpacity' />

        <SubTitle className='marginBottomMd wordWrap'>
          {order.userName}
        </SubTitle>
        <DisplayField
          label={<Body1>Phone Number:</Body1>}
          value={<Caption1>{phoneFormatForView(order.userPhone)}</Caption1>}
          className='marginBottomXs'
          spaceBetween
        />
        <DisplayField
          label={<Body1>Email Address:</Body1>}
          value={<Caption1 className='wordWrap'>{order.userEmail}</Caption1>}
          className='marginBottomXs'
          flexWrap
          flexItemFullWidth
          spaceBetween
        />

        <DisplayField
          label={<Body1>Location:</Body1>}
          value={<Caption1>{locationName}</Caption1>}
          className='marginBottomXl'
          spaceBetween
        />

        <BillDottedLine className='marginBottomXl' />

        {order.orderInfo?.items?.map((item) => (
          <>
            {mapOrderOptionsHelper(item, {}, useSalesPackage)}
            <div style={{position: 'relative', height: '20px'}}>
              <Caption1
                style={{
                  display: 'block',
                  position: 'absolute',
                  top: '0px',
                  right: '0'
                }}
              >
                {!useSalesPackage &&
                  `$${groupDigital(Number(deepCount(item) || 0).toFixed(2))}`}
              </Caption1>
            </div>
          </>
        ))}

        <BillDottedLine className='marginTopXl marginBottomXl' />

        {order.orderInfo?.specialNote && (
          <>
            <DisplayField
              label={<Body1>Note:</Body1>}
              value={<Caption1>{order.orderInfo.specialNote}</Caption1>}
              className='marginBottomXs'
              spaceBetween
            />
            <BillDottedLine className='marginBottomXl' />
          </>
        )}

        {!useSalesPackage && (
          <>
            <DisplayField
              label={<Caption1>Subtotal:</Caption1>}
              value={
                <Caption1>
                  ${' '}
                  {groupDigital(
                    Number(order.orderInfo.subtotal || 0).toFixed(2)
                  )}
                </Caption1>
              }
              spaceBetween
              className='marginBottomXs'
            />
            {!!order.adjustments?.length &&
              order.adjustments.map((adjustment) => (
                <DisplayField
                  label={<Caption1>Adjustment:</Caption1>}
                  value={
                    <Caption1>
                      ${' '}
                      {groupDigital(
                        Number(adjustment.SourceAmount || 0).toFixed(2)
                      )}
                    </Caption1>
                  }
                  spaceBetween
                  className='marginBottomXs'
                />
              ))}
            <DisplayField
              label={<Caption1>Tax:</Caption1>}
              value={
                <Caption1>
                  $ {groupDigital(Number(order.orderInfo.tax || 0).toFixed(2))}
                </Caption1>
              }
              spaceBetween
              className='marginBottomXs'
            />
            {!!Number(order.orderInfo.alcoholTax) && (
              <DisplayField
                label={<Caption1>Alcohol Tax:</Caption1>}
                value={
                  <Caption1>
                    ${' '}
                    {groupDigital(
                      Number(order.orderInfo.alcoholTax).toFixed(2)
                    )}
                  </Caption1>
                }
                spaceBetween
                className='marginBottomXs'
              />
            )}
            {!!Number(order.orderInfo.merchantOptionalServiceChargeAmount) && (
              <DisplayField
                label={<Caption1>Service Charge:</Caption1>}
                value={
                  <Caption1>
                    ${' '}
                    {groupDigital(
                      Number(
                        order.orderInfo.merchantOptionalServiceChargeAmount
                      ).toFixed(2)
                    )}
                  </Caption1>
                }
                spaceBetween
                className='marginBottomXs'
              />
            )}

            {!order.orderInfo?.IsDispatch && (
              <>
                {Number(order.orderInfo.delivery) ? (
                  <DisplayField
                    label={<Caption1>Delivery:</Caption1>}
                    value={
                      <Caption1>
                        ${' '}
                        {groupDigital(
                          Number(order.orderInfo.delivery || 0).toFixed(2)
                        )}
                      </Caption1>
                    }
                    className='marginBottomXs'
                    spaceBetween
                  />
                ) : null}
                {Number(order.orderInfo.tip) > 0 && (
                  <DisplayField
                    label={<Caption1>Tip:</Caption1>}
                    value={
                      <Caption1>
                        ${' '}
                        {groupDigital(
                          Number(order.orderInfo.tip || 0).toFixed(2)
                        )}
                      </Caption1>
                    }
                    className='marginBottomXs'
                    spaceBetween
                  />
                )}
              </>
            )}
            {discounts?.length > 0 &&
              discounts.map((discount) => (
                <DisplayField
                  label={
                    <ValueTitle className='wordWrap'>
                      Discount ({discount.Description}):
                    </ValueTitle>
                  }
                  value={
                    <Caption1>
                      - ${' '}
                      {groupDigital(
                        Number(
                          discount.MerchantDiscount || discount.Sum || 0
                        ).toFixed(2)
                      )}
                    </Caption1>
                  }
                  className='marginBottomXs'
                  spaceBetween
                />
              ))}
            <br />
            <DisplayField
              label={<SubTitle>Total:</SubTitle>}
              value={
                <Caption1>
                  ${' '}
                  {groupDigital(Number(order.orderInfo.total || 0).toFixed(2))}
                </Caption1>
              }
              spaceBetween
            />
            <BillDottedLine className='marginTopXl marginBottomSm' />
          </>
        )}
        <Caption1 className='textAlignCenter '>
          Thank you for your order. <br />
          Order directly with us and save up to 20-40% on every order.
        </Caption1>
      </BillContainer>
    );
  }
}

Bill.defaultProps = {
  useSalesPackage: false
};

Bill.propTypes = {
  order: PropTypes.object.isRequired,
  locationName: PropTypes.string.isRequired,
  useSalesPackage: PropTypes.bool
};
