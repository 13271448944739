import {request} from 'helpers/services/utils/request';
import {GET, PATCH, POST} from 'helpers/services/constants/API_CONSTANTS';
import {
  API_TOGGLE_PHONE_ORDERING,
  API_TOGGLE_ALL_LOCATIONS_PHONE_ORDERING,
  API_GET_LOCATIONS_PHONE_ORDERING,
  API_UPDATE_PHONE_ORDERING
} from 'helpers/services/constants/API_ENDPOINTS';

/**
 * @typedef {Object} PhoneOrderingLocations
 * @property {string} locationId
 * @property {string} locationName
 * @property {string|undefined} twilioPhone
 * @property {string|undefined} phoneOrderingWebsiteUrl
 */

/**
 * Get locations phone ordering
 * @return {Promise<PhoneOrderingLocations|undefined>}
 */
export const getLocationsPhoneOrderingApi = async () => {
  return request(GET, API_GET_LOCATIONS_PHONE_ORDERING());
};

/**
 * Toggle all locations phone ordering
 * @param state {boolean}
 * @return {Promise<unknown>}
 */
export const toggleAllLocationsPhoneOrderingApi = async (state) => {
  return request(POST, API_TOGGLE_ALL_LOCATIONS_PHONE_ORDERING(), {
    data: {isEnabled: state}
  });
};

/**
 * Update phone ordering
 * @param id {string}
 * @param data {phoneOrderingWebsiteUrl: string}
 * @return {Promise<unknown>}
 */
export const updatePhoneOrderingApi = async (id, data) => {
  return request(PATCH, API_UPDATE_PHONE_ORDERING(id), {data});
};

/**
 * Toggle phone ordering
 * @param id {string}
 * @param state {boolean}
 * @return {import('axios').AxiosResponse}
 */
export const togglePhoneOrderingApi = async (id, state) => {
  return request(POST, API_TOGGLE_PHONE_ORDERING(id), {
    data: {isEnabled: state}
  });
};
