import {combineReducers} from 'redux';
import authReducer from 'store/global/auth/reducer';
import user from 'store/global/user/reducer';
import reviewsReducer from 'store/reviews/reducer';

import menuEditorReducer from 'store/menuEditor/reducer';
import menuEditorModalsReducer from 'store/menuEditorModals/reducer';
import menuEditorCategoryReducer from 'store/menuEditorCategory/reducer';
import menuEditorItemReducer from 'store/menuEditorItems/reducer';
import menuEditorModifierGroupsReducer from 'store/menuEditorModifierGroups/reducer';
import menuEditorOutStock from 'store/menuEditorOutStock/reducer';
import menuEditorModifierReducer from 'store/menuEditorModifier/reducer';
import menuEditorHiddenReducer from 'store/menuEditorHidden/reducer';

import analyticsSalesCountReducer from 'store/analyticsSalesCount/reducer';
import analyticsCustomersCount from 'store/analyticsCustomersCount/reducer';
import analyticsSalesPerHourReducer from 'store/analyticsSalesPerHour/reducer';
import analyticsSalesPerItemReducer from 'store/analyticsSalesPerItem/reducer';
import uploadFileReducer from 'store/global/uploadFile/reducer';
import reportsReducer from 'store/reports/reducer';
import sessionExpiredReducer from 'store/global/sessionExpiredModal/reducer';
import ordersHistoryReducer from 'store/orders/orderList/reducer';
import orderDetailsReducer from 'store/orders/orderDetails/reducer';
import bookADriverCreateReducer from 'store/bookADriver/bookADriverCreate/reducer';
import locationSettingsReducer from 'store/locationSettings/reducer';
import accountReducer from 'store/global/account/reducer';
import setupRestaurantReducer from 'store/setupRestaurant/reducer';
import modalsReducer from 'store/global/modals/reducer';
import usersManagementReducer from 'store/usersManagement/reducer';
import menuQueueReducer from 'store/menuQueue/reducer';
import merchantsReducer from 'store/merchants/reducer';
import selfCreateAccountReducer from 'store/selfCreateAccount/reducer';
import globalNotificationsReducer from 'store/global/globalNotifications/reducer';
import discountsReducer from 'store/discounts/reducer';
import appVersionReducer from 'store/global/appVersion/reducer';
import adminUsersReducer from 'store/adminUsers/reducer';
import virtualNumberReducer from 'store/virtualNumber/reducer';
import activeOrdersReducer from 'store/global/orders/reducer';
import uberEatsReducer from 'store/uberEats/reducer';
import bookADriverListReducer from 'store/bookADriver/bookADriverList/reducer';
import bookADriverOpenDetails from 'store/bookADriver/bookADriverDetails/reducer';
import activeBookADriverOrdersReducer from 'store/global/bookADriver/reducer';
import bookADriverDeliveredOrdersReducer from 'store/global/bookADriverDelivered/reducer';
import featureManagementReducer from 'store/featureManagement/reducer';
import feedbackReducer from 'store/feedback/reducer';
import refundReducer from 'store/orders/orderRefund/reducer';
import realTimeBoardReducer from 'store/realTimeBoard/reducer';
import refundsCalculator from 'store/refundsCalculator/reducer';
import hubspotChatReducer from 'store/global/hubspotChat/reducer';
import emailMarketingReducer from 'store/emailMarketing/reducer';
import insightsGrowthReducer from 'store/insights/growth/reducer';
import insightsPerformanceReducer from 'store/insights/performance/reducer';
import phoneOrderingReducer from 'store/phoneOrdering/reducer';
import realTimeBoardV2Reducer from 'store/realTimeBoardV2/reducer';
import insightsControlReducer from 'store/insightsControl/reducer';
import orderAdjustReducer from 'store/orders/orderAdjust/reducer';
import findDeliveryReducer from 'store/findDelivery/reducer';
import cancellationRefundReducer from 'store/global/orders/CancellationRefund/reducer';

const reducers = {
  authReducer,
  sessionExpiredReducer,
  user,
  reviewsReducer,
  reportsReducer,
  menuEditorReducer,
  menuEditorModalsReducer,
  menuEditorCategoryReducer,
  menuEditorItemReducer,
  menuEditorModifierGroupsReducer,
  menuEditorModifierReducer,
  menuEditorOutStock,
  menuEditorHiddenReducer,
  analyticsSalesCountReducer,
  analyticsCustomersCount,
  analyticsSalesPerHourReducer,
  analyticsSalesPerItemReducer,
  uploadFileReducer,
  locationSettingsReducer,
  appVersionReducer,
  merchantsReducer,
  modalsReducer,
  setupRestaurantReducer,
  usersManagementReducer,
  accountReducer,
  selfCreateAccountReducer,
  menuQueueReducer,
  featureManagementReducer,
  ordersHistoryReducer,
  activeOrdersReducer,
  orderDetailsReducer,
  refundReducer,
  globalNotificationsReducer,
  discountsReducer,
  adminUsersReducer,
  virtualNumberReducer,
  uberEatsReducer,
  bookADriverCreateReducer,
  bookADriverListReducer,
  bookADriverOpenDetails,
  activeBookADriverOrdersReducer,
  bookADriverDeliveredOrdersReducer,
  feedbackReducer,
  realTimeBoardReducer,
  refundsCalculator,
  hubspotChatReducer,
  emailMarketingReducer,
  insightsGrowthReducer,
  insightsPerformanceReducer,
  insightsControlReducer,
  phoneOrderingReducer,
  realTimeBoardV2Reducer,
  findDeliveryReducer,
  cancellationRefundReducer,
  orderAdjustReducer
};

function createReducerManager() {
  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        const cloneState = {...state};
        keysToRemove.forEach((key) => {
          delete cloneState[key];
        });
        keysToRemove = [];
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action);
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    }
  };
}

export default createReducerManager;
