/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cn from 'classnames';
import IconSortActiveUp from '../../assets/icon-sort-active-up.svg';
import IconSortActiveDown from '../../assets/icon-sort-active-down.svg';
import Loading from '../shared/Utils/Loading';
import {TableSort, TableText, TableWrapper} from './styles';
import {Body1} from '../typography';
import {ORDER_DIRECTION} from '../../helpers/constants/Table';
import {
  checkIsFullNotToSauceRefundOrder,
  checkIsRefundOrder
} from '../../helpers/functionUtils/ordersUtils';
import {
  BOARD,
  BOARD_COLOR_PRIORITY,
  BOARD_ROW_BLINK
} from '../../helpers/constants/adminRealTImeBoard';
import {checkIsFutureOrder} from '../../routes/App/Managers/realTimeBoardV2/helpers';

const TableComponent = ({
  fields,
  orderBy,
  orderDirection,
  onSort,
  data,
  loading,
  rowKeyPath,
  customClass,
  hideHeader,
  isRtbV2,
  ...props
}) => {
  const renderRow =
    props.renderRow ||
    ((row, children) => (
      <TableRow
        key={rowKeyPath ? row[rowKeyPath] : nanoid()}
        tabIndex={-1}
        className={cn(
          'tableRow',
          {
            futureTableRow:
              checkIsFutureOrder(row) && !checkIsFullNotToSauceRefundOrder(row)
          },
          {
            refundTableRow:
              checkIsFullNotToSauceRefundOrder(row) ||
              (!checkIsFutureOrder(row) && checkIsRefundOrder(row))
          },
          {
            rdbGreyRow: isRtbV2
              ? checkIsFutureOrder(row?.pickup?.eta)
              : row.isFutureOrder
          },
          {
            rdbRedRow: row.colorPriority === BOARD_COLOR_PRIORITY.RED
          },
          {
            rdbOrangeRow: row.colorPriority === BOARD_COLOR_PRIORITY.ORANGE
          },
          {
            rdbGreenRow: row.colorPriority === BOARD_COLOR_PRIORITY.GREEN
          },
          {
            blink1times: row.blinkCount === BOARD_ROW_BLINK.ONCE
          },
          {
            blink3times: row.blinkCount === BOARD_ROW_BLINK.THREE_TIMES
          },
          {
            rdbLastOfTypeRow: row.lastOfType
          },
          {
            sameRow: row.isSameOrder
          },
          {
            sameFirst: row.isFirstOrder
          },
          {
            sameLast: row.isLastOrder
          }
        )}
      >
        {children}
      </TableRow>
    ));

  return (
    <TableWrapper>
      <Table>
        {loading && !data.length ? (
          <>
            <br />
            <TableBody>
              <TableRow className='tableRow'>
                <TableCell>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ) : (
          <>
            {!data.length ? (
              <>
                <br />
                <TableBody>
                  <TableRow className='tableRow'>
                    <TableCell>
                      <Body1>There&apos;s no data for now</Body1>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <>
                <TableHead className={hideHeader ? 'hideHeader' : ''}>
                  <TableRow>
                    {fields.map((field) => (
                      <TableCell
                        key={nanoid()}
                        sortDirection={
                          orderBy === field.fieldName ? orderDirection : false
                        }
                        className='headTableCell'
                      >
                        {!field.sortable ? (
                          field.title
                        ) : (
                          <TableSort
                            icon={
                              // eslint-disable-next-line no-nested-ternary
                              orderBy === field.fieldName
                                ? orderDirection === ORDER_DIRECTION.DESC
                                  ? IconSortActiveDown
                                  : IconSortActiveUp
                                : null
                            }
                            active={orderBy === field.fieldName}
                            direction={
                              orderBy === field.fieldName
                                ? orderDirection
                                : ORDER_DIRECTION.DESC
                            }
                            onClick={() =>
                              onSort({
                                sortBy: field.fieldName,
                                sort:
                                  orderDirection === ORDER_DIRECTION.ASC
                                    ? ORDER_DIRECTION.DESC
                                    : ORDER_DIRECTION.ASC
                              })
                            }
                          >
                            {field.title}
                          </TableSort>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => {
                    return (
                      <>
                        {renderRow(
                          row,
                          fields.map((field) => (
                            <>
                              <TableCell
                                onClick={
                                  field.onCellClick
                                    ? () => field.onCellClick(row)
                                    : null
                                }
                                style={
                                  field.onCellClick ? {cursor: 'pointer'} : null
                                }
                              >
                                <TableText className={customClass}>
                                  {field.renderer
                                    ? field.renderer(row)
                                    : row[field.fieldName]}
                                </TableText>
                              </TableCell>
                            </>
                          ))
                        )}
                        <div className='TableBottomIndent' />
                      </>
                    );
                  })}
                </TableBody>
              </>
            )}
          </>
        )}
      </Table>
      {loading && data.length > 0 && <Loading />}
    </TableWrapper>
  );
};

TableComponent.propTypes = {
  fields: PropTypes.array.isRequired,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  rowKeyPath: PropTypes.string,
  renderRow: PropTypes.func,
  customClass: PropTypes.string,
  hideHeader: PropTypes.bool,
  isRtbV2: PropTypes.bool
};

TableComponent.defaultProps = {
  orderBy: '',
  orderDirection: '',
  loading: '',
  onSort: () => {},
  rowKeyPath: null,
  renderRow: null,
  customClass: null,
  hideHeader: false,
  isRtbV2: false
};

export default TableComponent;
