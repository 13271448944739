export const refundFaultTypes = {
  0: 'Restaurant',
  1: 'DeliveryCompany',
  2: 'Sauce'
};

export const REFUND_FAULT_TITLE = {
  Restaurant: 'Restaurant',
  DeliveryCompany: 'Delivery company',
  Sauce: 'Sauce'
};

export const REFUND_SOURCE = {
  Sauce: 'Calculator',
  External: 'External',
  Restaurant: 'Merchant refund button'
};

export const RESTAURANT_REFUND_TYPE = {
  Full: 0,
  Partial: 1
};

export const RESTAURANT_REFUND_INITIATOR = {
  Restaurant: 0,
  Customer: 1
};

export default refundFaultTypes;
