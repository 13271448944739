import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import T from 'prop-types';
import React from 'react';
import cn from 'classnames';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {DialogBody} from 'routes/App/dashboard/styles';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {
  MAKE_REFUND,
  OPEN_CANCELLATION_REFUND_MODAL
} from 'store/global/orders/CancellationRefund/action';
import {
  RESTAURANT_REFUND_INITIATOR,
  RESTAURANT_REFUND_TYPE
} from 'helpers/constants/refundCalculator';
import AnimatePoints from 'components/AnimatePoints';

const CancellationRefundModal = ({
  openModal,
  invoiceId,
  showCannotDeliveryText,
  loadingRefund
}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(
      OPEN_CANCELLATION_REFUND_MODAL({
        isOpenModal: false,
        invoiceId: null,
        showCannotDeliveryText: false
      })
    );
  };

  const makeRefund = () => {
    dispatch(
      MAKE_REFUND({
        data: {
          initiator: RESTAURANT_REFUND_INITIATOR.Restaurant,
          reason: 'Order was canceled',
          type: RESTAURANT_REFUND_TYPE.Full
        },
        invoiceId
      })
    );
  };

  return (
    <Dialog open={openModal}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom verticalCenter'>
          {showCannotDeliveryText
            ? 'Cannot cancel delivery as it is already picked up.'
            : 'The order has been canceled.'}
          {!!invoiceId && (
            <>
              <br />
              <br />
              Do you want to refund the customer now?
            </>
          )}
        </DialogTitle>

        <DialogBody>
          {invoiceId ? (
            <DialogActions>
              <DialogAction className='DialogActions w50'>
                <ButtonOutline
                  className={cn('', {
                    disabled: loadingRefund
                  })}
                  onClick={onClose}
                >
                  <span>No, go back</span>
                </ButtonOutline>
              </DialogAction>
              <DialogAction className='DialogActions w50'>
                <ButtonPrimary
                  className={cn('', {disabled: loadingRefund})}
                  onClick={makeRefund}
                >
                  {loadingRefund ? (
                    <div className='CancellationRefundButton'>
                      Refunding{' '}
                      <AnimatePoints className='White CancellationRefund' />
                    </div>
                  ) : (
                    <span>Yes, refund</span>
                  )}
                </ButtonPrimary>
              </DialogAction>
            </DialogActions>
          ) : (
            <DialogActions className='justifyCenter'>
              <DialogAction className='DialogActions w50'>
                <ButtonPrimary onClick={onClose}>
                  <span>Ok</span>
                </ButtonPrimary>
              </DialogAction>
            </DialogActions>
          )}
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

CancellationRefundModal.propTypes = {
  openModal: T.bool.isRequired,
  invoiceId: T.string.isRequired,
  showCannotDeliveryText: T.bool.isRequired,
  loadingRefund: T.bool.isRequired
};

export default CancellationRefundModal;
