import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {TableCell} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {TablePagination, TablePaginationText} from '../../dashboard/styles';
import {
  FlexContainer,
  FlexItem,
  MainPaper
} from '../../../../components/layout';
import Headline from '../../../../components/headline2/HeadlineComponentStyle';
import Table from '../../../../components/Table';
import MERCHANT from '../../../../helpers/constants/merchants';
import {
  PAGE_CHANGE,
  SEARCH_CHANGE,
  SORT_CHANGE,
  CHANGE_USER,
  GET_ACTIVE_MERCHANTS,
  CLEAR_MERCHANT,
  GET_MERCHANT_SUCCESS,
  CLEAR_FILTERS,
  SET_OWNER_CREATION_MERCHANT_ID
} from '../../../../store/merchants/action';
import ButtonMore from '../components/ButtonMore';
import {ButtonOutline} from '../../../../components/buttons';
import SearchField from '../../../../components/fields/SearchField';
import EditActiveMerchant from './modals/EditActiveMerchant';
import AddOwner from '../MerchantsUrls/modals/AddOwner';

const getTableModel = (dispatch) => [
  {
    title: 'Merchant',
    renderer: (item) => <TableCell>{item.MerchantName}</TableCell>,
    width: '25%'
  },
  {
    title: 'Contact Full Name',
    width: '25%',
    renderer: (item) => (
      <TableCell>
        {`${item.Owner?.first_name || ''} ${item.Owner?.last_name || ''}`}
      </TableCell>
    )
  },
  {
    title: 'Email',
    width: '25%',
    renderer: (item) => <TableCell>{item.Owner?.email}</TableCell>
  },
  {
    width: '5%',
    renderer: (item) => (
      <ButtonMore
        itemsData={[
          item.Owner && {
            title: 'Edit',
            action: () => dispatch(GET_MERCHANT_SUCCESS(item))
          },
          item.Owner
            ? {
                title: 'Login',
                action: () => dispatch(CHANGE_USER(item.Owner?._id))
              }
            : {
                title: 'Create Owner',
                action: () => dispatch(SET_OWNER_CREATION_MERCHANT_ID(item.id))
              }
        ]}
      />
    )
  }
];

export default function ActiveUsers() {
  const dispatch = useDispatch();

  const {
    data,
    paging: {total, limit, pages, page},
    sort,
    selectedRecord,
    ownerCreationMerchantId
  } = useSelector((state) => state.merchantsReducer);

  useEffect(() => {
    return () => dispatch(CLEAR_FILTERS());
  }, []);

  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    dispatch(GET_ACTIVE_MERCHANTS({page, searchField, sort}));
  }, [page, sort]);

  const onPageChange = (newPage) => {
    dispatch(PAGE_CHANGE(newPage.selected));
  };

  const onSearchClick = () => {
    dispatch(SEARCH_CHANGE(searchField));
    dispatch(
      GET_ACTIVE_MERCHANTS({
        page,
        search: encodeURIComponent(searchField),
        sort
      })
    );
  };

  const closeDialog = () => {
    dispatch(CLEAR_MERCHANT());
  };

  const onClearSearchClick = () => {
    setSearchField('');
    dispatch(SEARCH_CHANGE(''));
    dispatch(GET_ACTIVE_MERCHANTS({page, search: '', sort}));
  };

  const onTableSort = ({sortBy, sortDirection}) => {
    dispatch(SORT_CHANGE({sortBy, sortDirection}));
  };

  return (
    <>
      <>
        <Headline className='marginBottomXs'>Active Merchants</Headline>
        <FlexContainer>
          <FlexItem flex='1 1 auto' className='marginRightXs'>
            <SearchField
              className='marginBottomXs'
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              onClear={onClearSearchClick}
              onKeyPress={(e) => e.key === 'Enter' && onSearchClick()}
            />
          </FlexItem>
          <FlexItem>
            <ButtonOutline
              className={cn('ButtonMedium', {disabled: !searchField})}
              onClick={onSearchClick}
            >
              Search
            </ButtonOutline>
          </FlexItem>
        </FlexContainer>
        <MainPaper>
          {total ? (
            <Table
              tableModel={getTableModel(dispatch)}
              data={data}
              rowKey={MERCHANT.ID}
              sortBy={sort.sortBy}
              sortDirection={sort.sortDirection}
              onSort={onTableSort}
            />
          ) : (
            <div>There&apos;s no merchants here</div>
          )}

          <TablePagination>
            <TablePaginationText>
              Showing {limit > total ? total : limit} out of {total} data
            </TablePaginationText>
            <ReactPaginate
              previousLabel='previous'
              nextLabel='next'
              breakLabel='...'
              breakClassName='break-me'
              pageCount={pages}
              initialPage={page}
              forcePage={page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={onPageChange}
              containerClassName='pagination'
              subContainerClassName='pages pagination'
              activeClassName='active'
            />
          </TablePagination>
        </MainPaper>
      </>
      {selectedRecord && <EditActiveMerchant onClose={closeDialog} />}

      {ownerCreationMerchantId && <AddOwner />}
    </>
  );
}
