import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Headline from 'components/Headline/styles';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {DashboardHeaderActions} from 'components/DashboardHeaderActions/styles';
import ConfirmModal from 'components/ModalBox/ConfirmModal';
import Loading from 'components/shared/Utils/Loading';

import {ReviewsInner} from 'routes/App/Managers/menuQueue/MenuQueueContainerStyle';

import {
  GET_LOCATIONS_PHONE_ORDERING,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING,
  TOGGLE_PHONE_ORDERING
} from 'store/phoneOrdering/action';

import PhoneOrderingTable from './Components/PhoneOrderingTable';
import PhoneOrderingContainer from './styles';
import EditPhoneOrderingLocation from './Components/Modal/EditPhoneOrderingLocation';

const PhoneOrdering = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [togglePhoneOrdering, setTogglePhoneOrdering] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalModel, setModalModel] = useState({});

  const dispatch = useDispatch();
  const locations = useSelector(
    (state) => state.phoneOrderingReducer.locations
  );
  const isLoading = useSelector(
    (state) => state.phoneOrderingReducer.isLoading
  );

  const togglePhoneOrderingHandler = (state) => {
    setTogglePhoneOrdering(state);
    setShowConfirm(true);
  };

  useEffect(() => {
    dispatch(GET_LOCATIONS_PHONE_ORDERING());
  }, []);

  return (
    <PhoneOrderingContainer>
      <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
        <DashboardHeader>
          <Headline>Phone Ordering</Headline>
          <DashboardHeaderActions>
            <ButtonPrimary
              className='ButtonMedium'
              type='button'
              onClick={() => togglePhoneOrderingHandler(true)}
              style={{marginLeft: 'auto'}}
            >
              <span>Switch all on</span>
            </ButtonPrimary>
            <ButtonPrimary
              className='ButtonMedium'
              type='button'
              onClick={() => togglePhoneOrderingHandler(false)}
              style={{marginLeft: 'auto'}}
            >
              <span>Switch all off</span>
            </ButtonPrimary>
          </DashboardHeaderActions>
        </DashboardHeader>
        {!isLoading ? (
          <ReviewsInner>
            <PhoneOrderingTable
              loading={false}
              headCells={[
                {id: 'locationName', label: 'Location Name'},
                {
                  id: 'phoneOrderingWebsiteUrl',
                  label: 'Phone Ordering Website'
                },
                {id: 'twilioPhone', label: 'Twilio Phone'},
                {id: 'isPhoneOrderingEnabled', label: 'Phone Ordering Enabled'}
              ]}
              items={locations}
              togglePhoneOrdering={(row, state) =>
                dispatch(
                  TOGGLE_PHONE_ORDERING({
                    locationId: row.locationId,
                    state
                  })
                )
              }
              editPhoneOrdering={(row) => {
                setModalModel(row);
                setOpenModal(true);
              }}
            />
          </ReviewsInner>
        ) : (
          <Loading />
        )}
      </DashboardContent>
      <ConfirmModal
        isOpen={showConfirm}
        title='Phone Ordering'
        message={`Are you sure you want to switch ${
          togglePhoneOrdering ? 'on' : 'off'
        } phone ordering?`}
        onCancel={() => setShowConfirm(false)}
        onOk={() => {
          dispatch(
            TOGGLE_ALL_LOCATIONS_PHONE_ORDERING({
              state: togglePhoneOrdering
            })
          );
          setShowConfirm(false);
        }}
      />
      <EditPhoneOrderingLocation
        open={openModal}
        modalModel={modalModel}
        onClose={() => {
          setOpenModal(!openModal);
          setModalModel({});
        }}
      />
    </PhoneOrderingContainer>
  );
};

export default PhoneOrdering;
