import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory, useLocation} from 'react-router-dom';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import MenuEditor from './menuEditor';
import EmailCheckModal from './modals/EmailCheckModal';
import refreshToken from './RefreshToken';
import withRestrictions from '../../hocs/withRestrictions';
import {
  FETCH_LOCATIONS_NOTIFICATIONS,
  POLL_STOP
} from '../../store/global/globalNotifications/action';
import Notifications from './components/Notifications';
import OnlineModal from './modals/OnlineModal';
import OfflineModal from './modals/OfflineModal';
import OnlineRoutes from './OnlineRoutes';
import OfflineRoutes from './OfflineRoutes';
import {STOP_GET_ACTIVE_ORDERS_JOB} from '../../store/global/orders/action';
import OrderStatusChangeModal from './orders/modals/OrderStatusChangeModal';
import SetupRoutes from './SetupRoutes';
import {STOP_BAD_DELIVERED_ORDERS_JOB} from '../../store/global/bookADriverDelivered/action';
import {FEATURE_RESTRICTION_IDS} from '../../helpers/constants/restrictions';
import RestrictedArea from '../../components/layout/RestrictedArea';
import UnPublish from '../../components/Publish/UnPublish';
import PublishProgressBar from '../../components/Publish/PublishProgressBar';
import UberEatsPromoBanner from './components/UberEatsPromoBanner';
import {CHECK_IS_ALLOW_MENU_PUBLISHING} from '../../store/menuEditor/action';
import {GET_UBEREATS_DATA} from '../../store/uberEats/actions';
import {
  TASK_TYPE_LAST_CUSTOM_DATA_UPDATE,
  TASK_TYPE_SETTINGS_DATA_UPDATE
} from '../../store/global/globalNotifications/constants';
import {checkIsMenuForbidden} from '../../helpers/menuEditor';
import RedBannerHolidays from '../../components/RedBannersStoreSettigs/RedBannerHolidays';
import RedBannerDisableStore from '../../components/RedBannersStoreSettigs/RedBannerDisableStore';
import CancellationRefundModal from './modals/CancellationRefund/CancellationRefundModal';
import OrderCancellationConfirmationModal from './orders/modals/OrderCancellation/OrderCancellationConfirmationModal';

const Index = ({restrictions}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);
  const locationId = useSelector((state) => state.user.activeLocation._id);
  const menuConfigId = useSelector(
    (state) => state.user.activeLocation?.MenuConfigId
  );
  const isToast = useSelector((state) => state.user.isToast);
  const isOnline = useSelector(
    (state) => state.globalNotificationsReducer.isNetworkOnline
  );
  const showPublishProgressBar = useSelector(
    (state) => state.globalNotificationsReducer.showPublishProgress
  );
  const showUnPublishBanner = useSelector(
    (state) => state.globalNotificationsReducer.unPublishBanner.showBanner
  );

  const formattedDateForShowBanner = (dateObj) => {
    return moment()
      .utc()
      .set({
        year: dateObj?.Year,
        month: dateObj?.Month - 1,
        date: dateObj?.Day,
        hour: dateObj?.Time?.Hour,
        minute: dateObj?.Time?.Minute
      });
  };

  const OpeningHoursExceptions = useSelector(
    (state) => state.user.activeLocation.OpeningHoursExceptions
  );
  const ClosedManually = useSelector(
    (state) => state.user.activeLocation.ClosedManually
  );
  const ClosedManuallyUntilTime = useSelector(
    (state) => state.user.activeLocation.ClosedManuallyUntilTime
  );

  const {
    isOpenModal,
    invoiceId,
    showCannotDeliveryText,
    loadingRefund
  } = useSelector((state) => state.cancellationRefundReducer);
  const {
    isOpenCancellationModal,
    orderCancellationSource,
    orderCancellationInfo,
    orderCancellationDeliveryProvider,
    loadingCancellation
  } = useSelector((state) => state.modalsReducer);
  // const startDateHolidays = OpeningHoursExceptions ? OpeningHoursExceptions[0]?.Start : null
  // const endDateHolidays = OpeningHoursExceptions ? OpeningHoursExceptions[0]?.End : null
  // const disableTimeFormat = formattedDateForShowBanner(startDateHolidays)

  const diffDateForShowBanner = (value) => {
    const duration = moment.duration(
      moment(value).diff(moment().utc().format())
    );
    const day = duration.asDays();
    return Math.floor(day) <= 1;
  };

  useEffect(() => {
    if (userData && !userData.pendingDataSetuped) {
      switch (
        location.pathname.includes('/my-account/sales-setup-new') ||
        location.pathname === '/sign-up-sales-new'
      ) {
        case true:
          if (
            location.pathname.includes(
              '/my-account/sales-setup-new/add-menu/uber-eats-connect'
            )
          ) {
            history.push(
              `/my-account/sales-setup-new/add-menu/uber-eats-connect${location.search}`
            );
          } else {
            history.push('/my-account/sales-setup-new');
          }
          break;
        default:
          switch (userData?.isSignUp2) {
            case false:
              history.push('/my-account/sales-setup-new');
              break;
            default:
              history.push('/my-account/self-setup');
          }
      }
    }
  }, []);

  useEffect(() => {
    const stopRefreshToken = refreshToken(dispatch);

    if (userData?.status === 'ACTIVE') {
      dispatch(FETCH_LOCATIONS_NOTIFICATIONS());
    }

    return () => {
      stopRefreshToken();
      dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
      dispatch(STOP_BAD_DELIVERED_ORDERS_JOB());
      dispatch(POLL_STOP());
    };
  }, []);

  useEffect(() => {
    if (userData?.status === 'ACTIVE') {
      dispatch(
        CHECK_IS_ALLOW_MENU_PUBLISHING({
          menuConfigId,
          SyncType: !checkIsMenuForbidden(menuConfigId)
            ? TASK_TYPE_LAST_CUSTOM_DATA_UPDATE
            : TASK_TYPE_SETTINGS_DATA_UPDATE
        })
      );
      // TODO remove or fix in feature GROW-84
      // dispatch(
      //   GET_UBEREATS_DATA({merchantId: userData?.merchant_id, locationId})
      // );
    }
  }, [locationId]);

  useEffect(() => {
    if (
      userData &&
      userData.pendingDataSetuped &&
      restrictions.includes('analyticsAccess') &&
      history.location.pathname.indexOf('/analytics') !== -1
    ) {
      history.push('/menu-editor');
    }
  }, [userData]);

  const restrictAccess = (condition, pathName, redirectTo) => {
    if (condition && location.pathname.includes(pathName)) {
      history.push(redirectTo);
      return true;
    }
    return false;
  };

  return (
    <>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
        extraAccessLogic={() => userData.status === 'ACTIVE'}
      >
        <UberEatsPromoBanner />
      </RestrictedArea>
      <OfflineModal />
      <OnlineModal />
      <OrderStatusChangeModal />
      <CancellationRefundModal
        openModal={isOpenModal}
        invoiceId={invoiceId}
        showCannotDeliveryText={showCannotDeliveryText}
        loadingRefund={loadingRefund}
      />
      <OrderCancellationConfirmationModal
        openModal={isOpenCancellationModal}
        cancellationInfo={orderCancellationInfo}
        cancellationSource={orderCancellationSource}
        cancellationDeliveryProvider={orderCancellationDeliveryProvider}
        loadingCancellation={loadingCancellation}
      />
      <EmailCheckModal />
      <Notifications />
      {ClosedManually && (
        <RedBannerDisableStore
          ClosedManuallyUntilTime={ClosedManuallyUntilTime}
        />
      )}
      {OpeningHoursExceptions &&
        OpeningHoursExceptions[0]?.Start &&
        OpeningHoursExceptions[0]?.End &&
        diffDateForShowBanner(
          formattedDateForShowBanner(OpeningHoursExceptions[0]?.Start)
        ) && (
          <RedBannerHolidays
            startDateHolidays={OpeningHoursExceptions[0]?.Start}
            endDateHolidays={OpeningHoursExceptions[0]?.End}
          />
        )}
      {showPublishProgressBar && !showUnPublishBanner && <PublishProgressBar />}
      {showUnPublishBanner && !showPublishProgressBar && <UnPublish />}

      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.MENU_EDITOR]}
        extraAccessLogic={() =>
          !isToast && isOnline && userData.pendingDataSetuped
        }
      >
        <Route path='/menu-editor' component={MenuEditor} />
      </RestrictedArea>

      {!userData.pendingDataSetuped && <SetupRoutes />}
      {restrictAccess(isToast && isOnline, '/menu-editor', '/')}
      {userData.pendingDataSetuped && <OfflineRoutes />}
      {isOnline && userData.pendingDataSetuped && <OnlineRoutes />}
    </>
  );
};

Index.propTypes = {
  restrictions: PropTypes.array.isRequired
};

export default withRestrictions(Index);
