import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import FieldInput from '../../../components/FieldInput';
import DialogFields from '../../../components/DialogFields/styles';
import DialogField from '../../../components/DialogField/styles';
import {FieldLabel} from '../../../components/FieldLabel/styles';
import {ButtonOutline, ButtonPrimary} from '../../../components/buttons';
import {ForgotPasswordSchema} from '../../../helpers/services/formValidationSchemas/AuthSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../components/errorSign/errorSign';
import {
  AuthBottom,
  AuthForm,
  AuthLink,
  AuthLinks,
  AuthLogo,
  AuthSection,
  AuthTitle
} from '../styles';
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_STATE_CHANGE
} from '../../../store/global/auth/action';
import LinkButton from '../../../components/LinkButton/styles';
import SauceLogo from '../../../assets/logo-sauce.svg';
import {NewPasswordImage} from '../../../components/NewPasswordImage/styles';
import NewPasswordImg from '../../../assets/image-new-password-inbox.svg';
import DialogTitle from '../../../components/DialogTitle/styles';
import {DialogAction} from '../../App/dashboard/styles';
import DialogBody from '../../../components/DialogBody/styles';
import history from '../../../history';
import LinkOutline from '../../../components/buttons/LinkOutline/styles';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FORGOT_PASSWORD_STATE_CHANGE({loading: false, success: false}));
  }, []);

  const {forgotPasswordSuccess, forgotPasswordLoading} = useSelector(
    (state) => state.authReducer
  );

  const formik = useFormik({
    initialValues: {
      Email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {resetForm}) => {
      dispatch(FORGOT_PASSWORD(values.Email.toLowerCase()));
      resetForm();
    }
  });

  return (
    <AuthSection>
      <form onSubmit={formik.handleSubmit}>
        <AuthLogo src={SauceLogo} />
        <AuthForm>
          {forgotPasswordSuccess ? (
            <DialogBody>
              <NewPasswordImage src={NewPasswordImg} />
              <DialogTitle className='marginBottomXl'>
                A new password is on its way. <br />
                Check your inbox!
              </DialogTitle>
              <DialogAction>
                <ButtonPrimary onClick={() => history.push('/')}>
                  <span>Ok</span>
                </ButtonPrimary>
              </DialogAction>
            </DialogBody>
          ) : (
            <>
              <AuthTitle>Forgot Password?</AuthTitle>
              <DialogFields>
                <DialogField className='w100 mTopMd'>
                  <FieldLabel className='FieldLabelLg mBottomMd'>
                    Email
                  </FieldLabel>
                  <FieldInput
                    className={
                      formik.errors.Email && formik.touched.Email && 'error'
                    }
                    name='Email'
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder='Email'
                  />
                  {formik.errors.Email && formik.touched.Email && (
                    <ErrorSignWrapper>
                      <ErrorSign>{formik.errors.Email}</ErrorSign>
                    </ErrorSignWrapper>
                  )}
                </DialogField>
              </DialogFields>
              <DialogFields className='verticalCenter mobInline'>
                <DialogField className='w50 mobileOrder2'>
                  <LinkOutline to='/' className='ButtonLg'>
                    Back to Log in
                  </LinkOutline>
                </DialogField>
                <DialogField className='w50 mobileOrder1'>
                  {!forgotPasswordSuccess && (
                    <ButtonPrimary type='submit'>
                      {forgotPasswordLoading ? (
                        <span>
                          <CircularProgress color='primary' size={16} />
                        </span>
                      ) : (
                        <span>Send</span>
                      )}
                    </ButtonPrimary>
                  )}
                </DialogField>
              </DialogFields>
            </>
          )}
        </AuthForm>
      </form>
      <AuthBottom>
        <span>© 2022 Sauce</span>
        <AuthLinks>
          <AuthLink
            href={process.env.REACT_APP_TERMS_SERVICE_URL}
            target='_blank'
          >
            Terms of Service
          </AuthLink>
          <AuthLink
            href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            target='_blank'
          >
            Privacy Policy
          </AuthLink>
        </AuthLinks>
      </AuthBottom>
    </AuthSection>
  );
};

export default ForgotPassword;
