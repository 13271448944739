import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import Headline from 'components/Headline/styles';
import {IconInsightsGrowth} from 'assets/IconInsightsGrowth';
import {
  InsightsGrowsMetricContainer,
  InsightsGrowsMetric,
  InsightsGrowthFeatureBlock,
  InsightsGrowthFeaturesContainer,
  InsightsGrowthFeatureName,
  InsightsGrowthFeatureDescription,
  InsightsGrowthFeatureMotivation,
  InsightsGrowthFeatureButtons,
  InsightsGrowthFeatureTop
} from 'routes/App/Insights/Growth/styles';
import {ButtonInfo} from 'components/buttons';
import {INSIGHTS_GROWTH_FEATURES_INFO} from 'helpers/constants/Insights';
import IconInsightsCheck from 'assets/insights/icon-insights-check.svg';
import {
  INSIGHTS_GROWTH_CLEAR_INFO,
  INSIGHTS_GROWTH_GET_INFO,
  INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL
} from 'store/insights/growth/action';
import ContactUsModal from 'routes/App/Insights/Growth/Modals/ContactUsModal';
import Loading from 'components/shared/Utils/Loading';
import {EmailMarketingSpinner} from 'routes/App/emailMarketing/components/styles';
import sendGA from 'helpers/services/utils/ga';

const showLearMoreButton = (feature) => {
  switch (feature) {
    case 'googleMarketing':
      // for POC
      return true;
    case 'catering':
      return false;
    default:
      return true;
  }
};

const showContactUsButton = (feature, allowedServiceFeatures) => {
  switch (feature) {
    case 'googleMarketing':
      // for POC
      return true;
    case 'emailMarketing':
      return !allowedServiceFeatures.includes('show_marketing_result');
    case 'phoneOrdering':
      return true;
    case 'virtualAnswering':
      return true;
    case 'catering':
      return true;
    case 'websiteOptimization':
      return true;
    default:
      return false;
  }
};
const InsightsGrowth = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const [rotateValue, setRotateValue] = useState(0);
  const {features, modalInfo, loading} = useSelector(
    (state) => state.insightsGrowthReducer
  );
  const {AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );

  const openContactUsModal = (feature) => {
    dispatch(INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL({isOpen: true, feature}));
  };

  useEffect(() => {
    dispatch(INSIGHTS_GROWTH_GET_INFO());
    return () => {
      dispatch(INSIGHTS_GROWTH_CLEAR_INFO());
    };
  }, [locationId]);

  useEffect(() => {
    if (features.length) {
      // setTimeout for animation
      setTimeout(() => {
        const totalWeightEnabled = features
          .filter((item) => item.status === 'Enabled')
          .reduce((sum, item) => sum + item.weight, 0);
        // 180 is half a circle and 100 is the max weight
        const calculateRotateValue =
          ((totalWeightEnabled > 100 ? 100 : totalWeightEnabled) / 100) * 180;
        setRotateValue(calculateRotateValue);
      }, 100);
    }
  }, [loading]);

  return (
    <>
      <DashboardContent
        className={cn({
          centerSpinner: loading
        })}
      >
        <DashboardHeader>
          <Headline className='mTop5'>Insights (Growth)</Headline>
        </DashboardHeader>
        <>
          {loading ? (
            <EmailMarketingSpinner>
              <Loading />
            </EmailMarketingSpinner>
          ) : (
            <>
              <InsightsGrowsMetricContainer>
                <InsightsGrowsMetric rotateValue={rotateValue}>
                  <div className='growthMetricTitle'>
                    <span>How Growth Optimized are you?</span>
                    <ButtonInfo className='InsightsGrowsMetric ButtonInfoDrop'>
                      <div className='DropInfo pointer-events-none DropInfoLeftMob'>
                        This view visualizes your efforts to boost your direct
                        online sales and increase your profits. <br />
                        <br /> The modules below are based on an analysis of
                        customer ordering over years of activity. The more
                        modules you add successfully, the higher you’ll score on
                        the Growth Optimizer and the more direct revenue you
                        will generate for your restaurant.
                      </div>
                    </ButtonInfo>
                  </div>
                  <IconInsightsGrowth />
                </InsightsGrowsMetric>
              </InsightsGrowsMetricContainer>
              <Headline className='MarginTop35 InsightsGrowsTitle'>
                Grow your direct online sales:
              </Headline>
              <InsightsGrowthFeaturesContainer>
                {features?.map((feature) => (
                  <InsightsGrowthFeatureBlock>
                    <div>
                      <InsightsGrowthFeatureTop>
                        <img
                          className='featureIcon'
                          src={
                            INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.icon
                          }
                          alt={
                            INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.name
                          }
                        />
                        {feature.status === 'Enabled' && (
                          <img
                            className='featureChecked'
                            src={IconInsightsCheck}
                            alt='checked'
                          />
                        )}
                      </InsightsGrowthFeatureTop>
                      <InsightsGrowthFeatureName>
                        {INSIGHTS_GROWTH_FEATURES_INFO[feature?.title]?.name}
                      </InsightsGrowthFeatureName>
                      <InsightsGrowthFeatureDescription>
                        {
                          INSIGHTS_GROWTH_FEATURES_INFO[feature.title]
                            ?.description
                        }
                      </InsightsGrowthFeatureDescription>
                    </div>
                    <div>
                      <InsightsGrowthFeatureMotivation>
                        {
                          INSIGHTS_GROWTH_FEATURES_INFO[feature.title]
                            ?.motivation
                        }
                      </InsightsGrowthFeatureMotivation>
                      <InsightsGrowthFeatureButtons>
                        {showLearMoreButton(feature.title) && (
                          <div>
                            <a
                              onClick={() => {
                                sendGA(
                                  'Insights Growth',
                                  'Learn more',
                                  `${
                                    INSIGHTS_GROWTH_FEATURES_INFO[feature.title]
                                      .name
                                  }`,
                                  null
                                );
                              }}
                              href={
                                INSIGHTS_GROWTH_FEATURES_INFO[feature.title]
                                  ?.learMore
                              }
                              target='_blank'
                              rel='noreferrer'
                            >
                              Learn more
                            </a>
                          </div>
                        )}

                        {showContactUsButton(
                          feature.title,
                          AllowedServiceFeatures
                        ) && (
                          <div>
                            <span
                              onClick={() => {
                                openContactUsModal(feature?.title);
                              }}
                            >
                              Contact us
                            </span>
                          </div>
                        )}
                      </InsightsGrowthFeatureButtons>
                    </div>
                  </InsightsGrowthFeatureBlock>
                ))}
              </InsightsGrowthFeaturesContainer>
            </>
          )}
        </>
      </DashboardContent>
      {modalInfo?.isOpen && (
        <ContactUsModal
          openModal={modalInfo.isOpen}
          feature={modalInfo.feature}
          featuresConst={INSIGHTS_GROWTH_FEATURES_INFO}
          closeModalAction={INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL}
          insightsType='Growth'
        />
      )}
    </>
  );
};

export default InsightsGrowth;
