import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import SignUpTemplate from '../components/SignUpTemplate';
import {
  SAVE_USER_EMAIL,
  SELF_SIGNUP_USER,
  SET_ENTERED_EMAIL
} from '../../../../store/global/auth/action';

const SignUp2 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(SET_ENTERED_EMAIL(false));
  }, []);
  return (
    <SignUpTemplate
      actionSignUp={(val) => dispatch(SELF_SIGNUP_USER(val))}
      showLogos
      actionSaveEmail={(val) => dispatch(SAVE_USER_EMAIL(val))}
    />
  );
};

export default SignUp2;
