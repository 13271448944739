import {isEqual} from 'lodash';

export const getPreparedItems = (arr = [], parentPath = []) => {
  let resultArr = arr ? [...arr] : [];
  const previousPath = [...parentPath];
  if (resultArr.length > 0) {
    resultArr = resultArr.map((item) => {
      const path = [...previousPath, item.index];
      return {
        ...item,
        path,
        checked: false,
        selected: item.quantity,
        modifiers: getPreparedItems(item.modifiers, path)
      };
    });
  }
  return resultArr;
};

export const unpackItems = (itemsArr = [], result = []) => {
  const resultArr = [];

  const unpack = (arr) => {
    arr.forEach((item) => {
      resultArr.push(item);
      if (item.modifiers.length > 0) {
        unpack(item.modifiers);
      }
    });
  };

  unpack(itemsArr);

  return resultArr;
};

export const checkedAllElements = (currentItem, items, keyName, keyValue) => {
  // flat map modifiers in one dimension array
  const getModList = (mods) =>
    mods.flatMap((mod) => [mod, ...getModList(mod.modifiers)]);

  // base case
  return items.map((item) => ({
    ...item,
    [keyName]:
      getModList(currentItem.modifiers)
        .concat(currentItem)
        .findIndex((el) => isEqual(el.path, item.path)) !== -1
        ? keyValue
        : item[keyName]
  }));
};

export const restaurantCheckedAllElements = (
  currentItem,
  items,
  keyName,
  keyValue
) => {
  const getModList = (mods) =>
    mods.flatMap((mod) => [mod, ...getModList(mod.modifiers)]);

  return items.map((item) => {
    // if (isEqual(item.path, currentItem.path)) {
    //   return item;
    // }

    const foundMod = getModList(currentItem.modifiers)
      .concat(currentItem)
      .find((el) => isEqual(el.path, item.path));

    if (foundMod) {
      const selectedValue = keyValue > item.quantity ? item.quantity : keyValue;
      return {
        ...item,
        [keyName]: selectedValue
      };
    }

    return item;
  });
};

export const initDataForRefundRequest = (arr) => {
  return arr.map((item) => {
    return {
      ...item,
      checked: false,
      selected: 1
    };
  });
};

export const groupForRestaurantRefund = (arr) => {
  const groupedArray = arr.reduce((acc, item) => {
    const firstIndex = item.path[0];
    if (!acc[firstIndex]) {
      acc[firstIndex] = [];
    }
    acc[firstIndex].push(item);
    return acc;
  }, {});

  return Object.values(groupedArray);
};

export const parseForRestaurantRefund = (arr) => {
  return arr.flat();
};

export const sortStepsForRefundCalculator = (data) => {
  return (
    data.reduce((acc, item) => {
      const {
        sourceId,
        refundSourceType,
        executionOrder,
        refundOperationType
      } = item.stepInfo;
      const {status} = item;

      let source = acc.find((el) => el.sourceId === sourceId);
      if (!source) {
        source = {
          sourceId,
          refundSourceType:
            refundSourceType === 'Order' ? refundSourceType : null,
          statuses: [],
          paymentIntentId: null,
          refundOperationType
        };
        acc.push(source);
      }

      if (refundSourceType === 'Order') {
        source.refundSourceType = refundSourceType;
      }

      if (!source.paymentIntentId) {
        const paymentIntent = data.find(
          (el) =>
            el.stepInfo.sourceId === sourceId && el.stepInfo.paymentIntentId
        );
        if (paymentIntent) {
          source.paymentIntentId = paymentIntent.stepInfo.paymentIntentId;
        }
      }

      source.statuses.push({executionOrder, status});

      source.statuses.sort((a, b) => a.executionOrder - b.executionOrder);

      return acc;
    }, []) || []
  );
};
