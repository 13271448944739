import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import BannerCommissionIcon from '../../../../assets/BannerCommissionIcon';
import BannerCarIcon from '../../../../assets/BannerCarIcon';
import {
  getSessionStorageItem,
  setSessionStorageItem
} from '../../../../helpers/sessionStorage';
import Banner from '../../../../components/Banner';
import {UBER_BANNER_SHOW} from '../../../../store/uberEats/actions';

const UberEatsPromoBanner = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const locHasMoreThan10Orders = useSelector(
    (state) => state.user.activeLocation?.hasMoreThan10Orders
  );
  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );
  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );
  const BYOCActivatedTime = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.activatedAt
  );

  const lastLoginTime = useSelector((state) => state.user?.track_login_time);

  const restrictedUrl = [
    '/uber-eats',
    '/uber-eats/byoc-wizard',
    '/sign-up-sales',
    '/sign-up-sales-new',
    '/sign-up',
    '/setup',
    '/setup2',
    '/my-account/sales-setup',
    '/my-account/sales-setup-new',
    '/my-account/self-setup'
  ];

  const generateUberBanner = (UEStatus, BYOCConfigured, BYOCConnected) => {
    if (
      !UEStatus &&
      !BYOCConfigured &&
      !BYOCConnected &&
      !getSessionStorageItem('ConnectUberBanner') &&
      restrictedUrl.every((item) => !location.pathname.includes(item)) &&
      locHasMoreThan10Orders
    ) {
      dispatch(UBER_BANNER_SHOW(true));
      return (
        <Banner
          linkText='Click to connect'
          mainText='your UberEats account and reduce UberEats delivery commission'
          onClick={() => history.push('/uber-eats')}
          icon={<BannerCommissionIcon />}
          onClose={() => {
            setSessionStorageItem('ConnectUberBanner', true);
            dispatch(UBER_BANNER_SHOW(false));
          }}
        />
      );
    }
    if (
      UEStatus &&
      !BYOCConfigured &&
      !BYOCConnected &&
      !getSessionStorageItem('ConfigureBYOCUberBanner') &&
      restrictedUrl.every((item) => !location.pathname.includes(item)) &&
      locHasMoreThan10Orders
    ) {
      dispatch(UBER_BANNER_SHOW(true));
      return (
        <Banner
          icon={<BannerCarIcon />}
          linkText='Click to use'
          mainText='Sauce couriers for your UberEats orders and cut UberEats commission by 50%'
          onClick={() => history.push('/uber-eats')}
          onClose={() => {
            setSessionStorageItem('ConfigureBYOCUberBanner', true);
            dispatch(UBER_BANNER_SHOW(false));
          }}
        />
      );
    }

    if (
      UEStatus &&
      BYOCConfigured &&
      BYOCConnected &&
      !getSessionStorageItem('ConnectedBYOCBanner') &&
      restrictedUrl.every((item) => !location.pathname.includes(item)) &&
      locHasMoreThan10Orders &&
      BYOCActivatedTime > lastLoginTime
    ) {
      dispatch(UBER_BANNER_SHOW(true));
      return (
        <Banner
          icon={<BannerCarIcon />}
          mainText='Congratulations! Sauce is now your courier for all your UberEats orders'
          onClose={() => {
            setSessionStorageItem('ConnectedBYOCBanner', true);
            dispatch(UBER_BANNER_SHOW(false));
          }}
        />
      );
    }
    return false;
  };

  return generateUberBanner(UEConnected, BYOCconfigured, BYOCconnected);
};

export default UberEatsPromoBanner;
