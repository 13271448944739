/* eslint-disable */
import {toast} from 'react-toastify';
import {
  call,
  cancel,
  put,
  race,
  select,
  take,
  takeEvery,
  delay,
  all
} from 'redux-saga/effects';
import {
  GET_MERCHANTS_URLS_SUCCESS,
  GET_MERCHANTS_URLS,
  CHANGE_NEW_MERCHANT,
  GET_MERCHANT,
  GET_MERCHANT_SUCCESS,
  CHANGE_USER,
  GET_ACTIVE_MERCHANTS,
  ACTIVATE_MERCHANT,
  GET_UNASSIGNED_MENU_QUEUE,
  GET_UNASSIGNED_MENU_QUEUE_SUCCESS,
  VERIFY_MERCHANT,
  FACEBOOK_INFO_MODAL,
  DEPLOY_OB_AUTOMATION,
  CHANGE_LOCATION,
  ACTIVATE_LOCATION,
  CHANGE_LOCATION_SUCCESS,
  ADD_OWNER,
  UPDATE_ACTIVE_MERCHANT,
  UPDATE_ACTIVE_MERCHANT_SUCCESS,
  RUN_CHECK_AUTOMATION_TEST_RESULTS_JOB,
  STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB,
  GET_TEST_AUTOMATION_RESULT,
  START_TEST_AUTOMATION,
  SET_TEST_AUTOMATION_RESULT,
  SET_TEST_AUTOMATION_JOB_ID,
  SET_SALES_PACKAGE,
  SET_SALES_PACKAGE_SUCCESS,
  SET_GMB_REVIEW_LINK_SUCCESS,
  SET_GMB_REVIEW_LINK,
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_SUCCESS
} from './action';
import {GET, POST, PUT} from '../../helpers/services/constants/API_CONSTANTS';
import {
  request,
  testAutomationService
} from '../../helpers/services/utils/request';
import {
  ACTIVE_MERCHANTS,
  LOCATION_ACTIVATE,
  LOCATION_PUT,
  MERCHANT_ACTIVATE,
  MERCHANT_CHANGE_USER,
  MERCHANT_MENU_QUEUE_UNASSIGNED,
  MERCHANT_PUT,
  MERCHANT_URLS,
  MERCHANT_URLS_ID,
  MERCHANT_VERIFY,
  MERCHANT_DEPLOY_OB_AUTOMATION,
  UPDATE_MERCHANT,
  TEST_AUTOMATION_JOB_ID,
  TEST_AUTOMATION_RESULT,
  TEST_AUTOMATION,
  API_UPDATE_LOCATION_SALES_PACKAGE,
  API_UPDATE_LOCATION_GMB_REVIEW_LINK
} from '../../helpers/services/constants/API_ENDPOINTS';
import {buildUrl} from '../../helpers/functionUtils/AdminBuildUrl';
import {createOwnerApi} from '../../helpers/services/api/account';
import {getLocationSettingsDataByIdApi} from '../../helpers/services/api/locationSettings';
import getServiceMenuItemsApi from '../../helpers/services/api/appSettings';

const getMerchantsState = ({merchantsReducer}) => merchantsReducer;

function* getMerchantsUrls({payload = {}}) {
  try {
    const data = yield request(GET, buildUrl(MERCHANT_URLS(), payload));

    if (data) {
      yield put(GET_MERCHANTS_URLS_SUCCESS(data.data));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* getActiveMerchants({payload = {}}) {
  try {
    const data = yield request(GET, buildUrl(ACTIVE_MERCHANTS(), payload));

    if (data) {
      yield put(GET_MERCHANTS_URLS_SUCCESS(data.data));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* changeMerchant({payload, refreshLink}) {
  try {
    const res = yield request(PUT, MERCHANT_PUT(payload.id), {
      data: payload.formValues
    });
    const {
      paging: {page},
      search,
      sort
    } = yield select(getMerchantsState);

    if (res.status === 204) {
      toast.success('Merchant was changed!');
      yield put(refreshLink({page, search, sort}));
      payload.successCb();
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* changeNewMerchant({payload}) {
  const {selectedRecord} = yield select((state) => state.merchantsReducer);
  const newLinkValue =
      payload.formValues.merchant.Locations[0]?.GeneratedLink.url,
    newRestaurantUrlValue = payload.formValues.merchant.RestaurantURL,
    oldRestaurantUrlValue = selectedRecord.merchant.RestaurantURL,
    oldLinkValue = selectedRecord.merchant.Locations[0]?.GeneratedLink.url;

  if (
    (newLinkValue == '' || newLinkValue === oldRestaurantUrlValue) &&
    oldRestaurantUrlValue === oldLinkValue
  ) {
    payload.formValues.merchant.Locations[0].GeneratedLink.url = newRestaurantUrlValue;
  }

  yield changeMerchant({payload, refreshLink: GET_MERCHANTS_URLS});
}

function* changeLocation({
  payload: {id, WixMenuConfigId, WixWebOrderingConfigId, GeneratedLink}
}) {
  try {
    const res = yield request(PUT, LOCATION_PUT(id), {
      data: {
        GeneratedLink,
        WixWebOrderingConfigId,
        WixMenuConfigId
      }
    });

    if (res.status === 201) {
      const {data} = yield getLocationSettingsDataByIdApi(id);

      yield put(CHANGE_LOCATION_SUCCESS(data));
      toast.success('Location was changed!');
    }
  } catch (e) {}
}

function* activateLocation({payload}) {
  try {
    const res = yield request(POST, LOCATION_ACTIVATE(payload));
    if (res.status === 201) {
      toast.success('Location was activated!');
    }
  } catch (e) {}
}

function* changeUser({payload}) {
  try {
    const {data} = yield request(GET, MERCHANT_CHANGE_USER(payload));
    if (data) {
      window.open(`${window.location.origin}?token=${data.token}`, '_blank');
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* getMerchant({payload}) {
  try {
    yield request(GET, MERCHANT_URLS_ID(payload)); // Fake request here, change in future
    yield put(GET_MERCHANT_SUCCESS({id: payload, name: 'new name'}));
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* activateMerchant({payload}) {
  try {
    yield request(POST, MERCHANT_ACTIVATE(payload));
    yield put(GET_MERCHANTS_URLS());

    toast.success('User was activated!');
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* verifyMerchant({payload: {id}}) {
  try {
    const {
      data: {type, message}
    } = yield request(POST, MERCHANT_VERIFY(id));

    switch (type) {
      case 'facebook':
        yield put(ACTIVATE_MERCHANT(id));
        break;
      case 'verified':
        break;
      default:
        toast.error(message);
        break;
    }
  } catch (e) {
    const errors = e.response && e.response.data.errors;
    toast.error(
      `Error occurred - please try again [${
        (errors && errors[0].message) || e
      }]`
    );
  }
}

function* deployObAutomation({payload: {_id, ...facebook}}) {
  try {
    const {data} = yield request(POST, MERCHANT_DEPLOY_OB_AUTOMATION(), {
      data: facebook
    });
    if (data) {
      yield put(FACEBOOK_INFO_MODAL({}));
    }
  } catch (e) {
    const errors = e.response && e.response.data.errors;
    toast.error(
      `Error occurred - please try again [${
        (errors && errors[0].message) || e
      }]`
    );
  }
}

function* unassignedMenuQueue() {
  try {
    const data = yield request(GET, MERCHANT_MENU_QUEUE_UNASSIGNED());

    if (data?.data) {
      yield put(GET_UNASSIGNED_MENU_QUEUE_SUCCESS(data.data));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* addOwner({payload}) {
  try {
    const {
      paging: {page},
      search,
      sort,
      ownerCreationMerchantId
    } = yield select(getMerchantsState);

    const {data} = yield createOwnerApi(ownerCreationMerchantId, {
      ...payload.form,
      flag: payload.form.flag ? 'no_menu' : undefined
    });

    if (data) {
      yield put(GET_ACTIVE_MERCHANTS({page, search, sort}));
      toast.success('Owner was added!');
      payload.onSuccess();
    }
  } catch (error) {
    // Error handle
  }
}

function* updateActiveMerchant({payload: {Locations, Owner, ...serverObj}}) {
  try {
    const {
      paging: {page},
      search,
      sort
    } = yield select(getMerchantsState);

    const res = yield request(PUT, UPDATE_MERCHANT(serverObj.id), {
      data: serverObj
    });

    if (res.status === 204) {
      yield put(GET_ACTIVE_MERCHANTS({page, search, sort}));
      yield put(UPDATE_ACTIVE_MERCHANT_SUCCESS());
      toast.success('Merchant has been updated!');
    }
  } catch (error) {
    const customErr = error?.response?.data?.errors?.[0];
    if (error?.response?.data?.status === 400) {
      toast.error(
        `${customErr?.message || 'Error occurred - please try again'}`
      );
    } else {
      toast.error(`Error occurred - please try again [${error}]`);
    }
  }
}

function* startTestAutomationOrder({payload}) {
  try {
    const result_id = (yield testAutomationService(POST, TEST_AUTOMATION(), {
      data: {
        url: payload.url.replace(/\/+$/, '') + '/order-online/',
        email: 'testorder@getsauce.com'
      }
    }))?.data?.result_id;

    if (result_id) {
      yield put(SET_TEST_AUTOMATION_JOB_ID({id: payload.id, jobId: result_id}));
      yield put(
        SET_TEST_AUTOMATION_RESULT({id: result_id, status: {name: 'pending'}})
      );
      yield request(PUT, TEST_AUTOMATION_JOB_ID(payload.id), {
        data: {jobId: result_id}
      });
    }
  } catch {}
}

function* runCheckTestAutomationResultsJob() {
  const {stop} = yield race({
    job: call(() => checkAllTestAutomationResults()),
    stop: take(STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB)
  });

  if (stop) {
    yield cancel();
  }
}

function* checkAllTestAutomationResults() {
  while (true) {
    const {selectedRecord} = yield select(getMerchantsState);
    const {locationTestJobStatuses} = yield select(getMerchantsState);

    yield all(
      selectedRecord?.Locations?.map((loc) => {
        if (
          loc.lastTestAutomationJob?.id &&
          (locationTestJobStatuses?.[loc.lastTestAutomationJob?.id]?.name ===
            'pending' ||
            !locationTestJobStatuses?.[loc.lastTestAutomationJob?.id])
        ) {
          return put(GET_TEST_AUTOMATION_RESULT(loc.lastTestAutomationJob?.id));
        }
      })
    );

    yield delay(2500);
  }
}

function* getTestAutomationResult({payload}) {
  try {
    const data = (yield testAutomationService(
      GET,
      TEST_AUTOMATION_RESULT(payload)
    ))?.data;

    let status = {};

    if (data?.status) {
      if (data.status === 'running') {
        status = {name: 'pending'};
      } else if (data.status === 'exited' && data.exit_code === 0) {
        status = {name: 'success', details: data.files};
      } else if (data.status === 'exited' && data.exit_code === 1) {
        status = {name: 'failed', details: data.files};
      } else {
        status = {name: 'not found'};
      }

      yield put(SET_TEST_AUTOMATION_RESULT({id: payload, status: status}));
    }
  } catch {}
}

function* setSalesPackageSaga({payload}) {
  const {
    LocationId,
    body,
    preCallBack = () => {},
    postCallBack = () => {}
  } = payload;
  try {
    preCallBack();
    const data = yield request(
      PUT,
      API_UPDATE_LOCATION_SALES_PACKAGE(LocationId),
      {data: {...body}}
    );

    if (data?.status === 204) {
      yield put(SET_SALES_PACKAGE_SUCCESS(payload));
      toast.success('Location Sales Package has been updated!');
      postCallBack();
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
    postCallBack();
  }
}

function* setGMBReviewLink({payload}) {
  const {
    LocationId,
    body,
    preCallBack = () => {},
    postCallBack = () => {}
  } = payload;
  try {
    preCallBack();
    const data = yield request(
      PUT,
      API_UPDATE_LOCATION_GMB_REVIEW_LINK(LocationId),
      {data: {...body}}
    );

    if (data?.status === 204) {
      yield put(SET_GMB_REVIEW_LINK_SUCCESS(payload));
      toast.success('Location GMB fields have been updated!');
      postCallBack();
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
    postCallBack();
  }
}

function* getFeatureFlags() {
  try {
    const {data} = yield getServiceMenuItemsApi();
    if (data) {
      yield put(GET_FEATURE_FLAGS_SUCCESS(data));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* merchantsSaga() {
  yield takeEvery(GET_MERCHANTS_URLS, getMerchantsUrls);
  yield takeEvery(GET_ACTIVE_MERCHANTS, getActiveMerchants);
  yield takeEvery(CHANGE_NEW_MERCHANT, changeNewMerchant);
  yield takeEvery(CHANGE_LOCATION, changeLocation);
  yield takeEvery(ACTIVATE_LOCATION, activateLocation);
  yield takeEvery(CHANGE_USER, changeUser);
  yield takeEvery(GET_MERCHANT, getMerchant);
  yield takeEvery(ACTIVATE_MERCHANT, activateMerchant);
  yield takeEvery(VERIFY_MERCHANT, verifyMerchant);
  yield takeEvery(DEPLOY_OB_AUTOMATION, deployObAutomation);
  yield takeEvery(GET_UNASSIGNED_MENU_QUEUE, unassignedMenuQueue);
  yield takeEvery(ADD_OWNER, addOwner);
  yield takeEvery(UPDATE_ACTIVE_MERCHANT, updateActiveMerchant);
  yield takeEvery(
    RUN_CHECK_AUTOMATION_TEST_RESULTS_JOB,
    runCheckTestAutomationResultsJob
  );
  yield takeEvery(GET_TEST_AUTOMATION_RESULT, getTestAutomationResult);
  yield takeEvery(START_TEST_AUTOMATION, startTestAutomationOrder);
  yield takeEvery(SET_SALES_PACKAGE, setSalesPackageSaga);
  yield takeEvery(SET_GMB_REVIEW_LINK, setGMBReviewLink);
  yield takeEvery(GET_FEATURE_FLAGS, getFeatureFlags);
}

export default merchantsSaga;
