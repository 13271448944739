import {put, select, takeEvery} from 'redux-saga/effects';
import {
  getDispatchInfoApi,
  getOrderApi
} from '../../../helpers/services/api/orders';
import {
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  GET_DISPATCH_INFO,
  GET_DISPATCH_INFO_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS
} from './action';
import {changeStatusActiveOrder} from '../../global/orders/saga';
import {changeStatusHistory} from '../orderList/saga';
import {SET_LOADING_CANCELLATION} from '../../global/modals/action';

export function* getOrder({payload}) {
  try {
    const {Timezone} = yield select((state) => state.user.activeLocation);
    const {data} = yield getOrderApi(payload);

    if (data) {
      yield put(GET_ORDER_SUCCESS({Timezone, ...data}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getDispatchInfo({payload}) {
  try {
    const {data} = yield getDispatchInfoApi(payload);

    if (data) {
      yield put(GET_DISPATCH_INFO_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* changeStatus({payload: {location, ...values}}) {
  try {
    yield put(SET_LOADING_CANCELLATION(true));
    let success;

    if (location === 'history') {
      success = yield changeStatusHistory({payload: {...values}});
    } else {
      success = yield changeStatusActiveOrder({payload: {...values}});
    }

    if (success) {
      yield put(CHANGE_STATUS_SUCCESS(success));
    }
    yield put(SET_LOADING_CANCELLATION(false));
  } catch (error) {
    yield put(SET_LOADING_CANCELLATION(false));
    console.log('SAGA ERROR', error);
  }
}

function* orderDetailsSaga() {
  yield takeEvery(GET_ORDER, getOrder);
  yield takeEvery(CHANGE_STATUS, changeStatus);
  yield takeEvery(GET_DISPATCH_INFO, getDispatchInfo);
}

export default orderDetailsSaga;
