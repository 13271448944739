import IconInsightsGoogle from 'assets/insights/icon-insights-google.svg';
import IconInsightsEmail from 'assets/insights/icon-insights-email.svg';
import IconInsightsDiscount from 'assets/insights/icon-insights-discount.svg';
import IconInsightsPhoneOrdering from 'assets/insights/icon-insights-phone-ordering.svg';
import IconInsightsMenu from 'assets/insights/icon-insights-menu.svg';
import IconInsightsSocial from 'assets/insights/icon-insights-social.svg';
import IconInsightsManagePrices from 'assets/insights/icon-insights-manage-prices.svg';
import IconInsightsFreeDelivery from 'assets/insights/icon-insights-free-delivery.svg';
import IconInsightsVA from 'assets/insights/icon-insights-va.svg';
import IconInsightsCatering from 'assets/insights/icon-insights-catering.svg';
import IconInsightsWebsiteOptimization from 'assets/insights/icon-insights-website-optimization.svg';

export const INSIGHTS_GROWTH_FEATURES_INFO = {
  googleMarketing: {
    name: 'Google Marketing',
    description: "Don't let 3rd parties hijack your customers!",
    motivation:
      'Removing other links from your Google listing can boost orders by over 30%',
    learMore:
      'https://support.getsauce.com/edit-your-google-my-business-website',
    icon: IconInsightsGoogle,
    nameForRequest: 'google-marketing'
  },
  freeDelivery: {
    name: 'Free Delivery',
    description: 'Beat the competition with a free delivery offer',
    motivation:
      'FREE DELIVERY can convert customers to your direct menu by up to 26%!',
    learMore: 'https://support.getsauce.com/free-delivery',
    icon: IconInsightsFreeDelivery,
    disableInsightControlModule: true
  },
  menuPhotos: {
    name: 'Menu Photos',
    description: 'Are you making the most of photos on your online menu?',
    motivation:
      'Good photography can increase order volume and item count by over 21%',
    learMore: 'https://support.getsauce.com/menu-photography',
    icon: IconInsightsMenu,
    disableInsightControlModule: true
  },
  managePrices: {
    name: 'Manage Prices',
    description: 'Create a cheaper, better offer  for your direct customers!',
    motivation:
      'Better menu pricing can convert up to 40% of your 3PD customers',
    learMore: 'https://support.getsauce.com/manage-prices',
    icon: IconInsightsManagePrices
  },
  discounts: {
    name: 'Discounts',
    description: 'Encourage your loyal customers to order every day!',
    motivation:
      '“Money off” discounts will create a sense of urgency for your customers',
    learMore: 'https://support.getsauce.com/discounts',
    icon: IconInsightsDiscount,
    disableInsightControlModule: true
  },
  socialMedia: {
    name: 'Social Media',
    description: 'Turn your social media into a revenue making machine!',
    motivation:
      'The right promotion can increase your website visits by over 150%',
    learMore: 'https://support.getsauce.com/social-media',
    icon: IconInsightsSocial
  },
  virtualAnswering: {
    name: 'Virtual Answering',
    description: 'Save time & reduce workloads. We’ll look after the phone!',
    motivation:
      'Virtual answering saves on labor and converts into direct orders by up to 28%',
    learMore: 'https://support.getsauce.com/introducing-virtual-number',
    icon: IconInsightsVA,
    nameForRequest: 'virtual-answering'
  },
  // phoneOrdering: {
  //   name: 'Phone Ordering',
  //   description: 'Catch every order without increasing operational costs',
  //   motivation:
  //     'Our agents can take over 10 orders at the same time over the phone!',
  //   learMore: 'https://support.getsauce.com/phone-ordering',
  //   icon: IconInsightsPhoneOrdering,
  //   nameForRequest: 'phone-ordering'
  // },
  emailMarketing: {
    name: 'Email Marketing',
    description:
      'Create a channel for your loyal customers to keep coming back',
    motivation:
      'Customer engagement is known to boost revenues by as much as 40%',
    learMore: 'https://support.getsauce.com/Email-Marketing',
    icon: IconInsightsEmail,
    nameForRequest: 'email-marketing',
    disableInsightControlModule: true
  },
  websiteOptimization: {
    name: 'Website Optimization',
    description:
      'Is your website optimized for mobile with a single ordering link?',
    motivation:
      'Optimizing customer experience for mobile can boost orders x2!',
    icon: IconInsightsWebsiteOptimization,
    nameForRequest: 'website-optimization',
    learMore:
      'https://support.getsauce.com/optimizing-your-website-for-more-orders?hs_preview=qgPojliQ-164438229527'
  },
  catering: {
    name: 'Catering',
    description: 'Build a new revenue channel for your business',
    motivation:
      'Catering orders are, on average, 3X bigger than regular orders',
    icon: IconInsightsCatering,
    nameForRequest: 'catering',
    disableInsightControlModule: true
  }
};

export const MODULE_WEIGHT_WITHOUT_CATERING = {
  // high priority = 15.38
  // high priority without phone ordering = 15.97
  googleMarketing: 15.97,
  freeDelivery: 15.97,
  emailMarketing: 15.97,
  websiteOptimization: 15.97,
  // medium priority = 7.69
  // medium priority without phone ordering = 7.99
  menuPhotos: 7.99,
  managePrices: 7.99,
  discounts: 7.99,
  socialMedia: 7.99,
  // low priority = 3.86
  // low priority without phone ordering = 4.01
  virtualAnswering: 4.01
  // phoneOrdering: 3.86
};
export const MODULE_WEIGHT_WITH_CATERING = {
  // high priority = 13.33
  // high priority without phone ordering = 13.76
  googleMarketing: 13.76,
  freeDelivery: 13.76,
  emailMarketing: 13.76,
  websiteOptimization: 13.76,
  catering: 13.76,
  // medium priority = 6.67
  // medium priority without phone ordering = 6.89
  menuPhotos: 6.89,
  managePrices: 6.89,
  discounts: 6.89,
  socialMedia: 6.89,
  // low priority = 3.33
  // low priority without phone ordering = 3.46
  virtualAnswering: 3.44
  // phoneOrdering: 3.33
};
