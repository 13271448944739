import React from 'react';
import moment from 'moment/moment';
import {useDispatch, useSelector} from 'react-redux';
import {
  FeedbackItem,
  FeedbackItems,
  FeedbackPanelContainer
} from '../../styles';
import {FlexContainer} from '../../../../../components/layout';
import Subtitle6 from '../../../../../components/typography/Subtitle6';
import {ButtonInfo} from '../../../../../components/buttons';
import Body0 from '../../../../../components/typography/Body0';
import {
  DashboardField,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  FieldLabel
} from '../../../dashboard/styles';
import DatepickerModule from '../../../../../components/DatepickerModule';
import {
  GET_FEEDBACK_DATA,
  SET_FILTER
} from '../../../../../store/feedback/action';
import Subtitle2 from '../../../../../components/typography/Subtitle2';
import Subtitle from '../../../../../components/typography/Subtitle1';
import {feedbackRankingTypes} from '../../constants';

export const FeedbackPanel = () => {
  const dispatch = useDispatch();

  const {
    SelectedTypeSurveysCount,
    GMBReviewClicks,
    CompletedSurveys,
    Views,
    SurveyAnswers
  } = useSelector((state) => state.feedbackReducer.data);

  const tabIndex = useSelector(
    (state) => state.feedbackReducer.filters.tabIndex
  );

  const {datePickerSelectIndex, startDate, endDate} = useSelector(
    (state) => state.feedbackReducer.filters
  );
  const Timezone = useSelector((state) => state.user.activeLocation.Timezone);

  const locationDate = () => {
    return moment().utcOffset(Timezone, false).utc();
  };

  const datePickerStartDate =
    startDate || locationDate().subtract(40, 'year').startOf('day').format();
  const datePickerEndDate = endDate || locationDate().endOf('isoWeek').format();

  const getFeedback = () => {
    dispatch(GET_FEEDBACK_DATA());
  };

  const onFilterChange = (filter) => {
    dispatch(SET_FILTER(filter));
    getFeedback();
  };

  const updateDate = (date) => {
    if (date && date[0] && date[1]) {
      onFilterChange({
        startDate: date[0],
        endDate: date[1]
      });
    }
  };

  const updateSelectedDateIndex = (selectedIndex) => {
    dispatch(
      SET_FILTER({
        datePickerSelectIndex: selectedIndex
      })
    );
  };

  const displayStatsRestaurantClosed = () =>
    SurveyAnswers?.find(
      (el) => el.Name === feedbackRankingTypes.BadFeedback && el.Value === 4
    )?.Occurrence || 0;

  return (
    <FeedbackPanelContainer>
      <FeedbackItems>
        <FeedbackItem>
          <FlexContainer alignItems='center'>
            <Subtitle className='Subtitle'>SMS sent</Subtitle>
            <ButtonInfo className='OutlineLg ButtonInfoDrop'>
              <div className='DropInfo DropInfoCenter pointer-events-none DropInfoLeftTablet DropInfoCenterMob Small'>
                Number of post-order survey messages sent to your customers by
                SMS.
              </div>
            </ButtonInfo>
          </FlexContainer>
          <Body0 className='Body0'>{SelectedTypeSurveysCount || 0}</Body0>
        </FeedbackItem>
        <FeedbackItem>
          <FlexContainer alignItems='center'>
            <Subtitle className='Subtitle'>Views</Subtitle>
            <ButtonInfo className='OutlineLg ButtonInfoDrop'>
              <div className='DropInfo DropInfoCenter pointer-events-none DropInfoRightMob Small'>
                Numbers of unique customers who clicked the link on SMS and
                visited the survey page.
              </div>
            </ButtonInfo>
          </FlexContainer>
          <Body0 className='Body0'>{Views}</Body0>
        </FeedbackItem>
        <FeedbackItem>
          <FlexContainer alignItems='center'>
            <Subtitle className='Subtitle'>Completed</Subtitle>
            <ButtonInfo className='OutlineLg ButtonInfoDrop'>
              <div className='DropInfo DropInfoCenter pointer-events-none Small'>
                Number of completed post-order surveys.
              </div>
            </ButtonInfo>
          </FlexContainer>
          <Body0 className='Body0'>{CompletedSurveys}</Body0>
        </FeedbackItem>
        <FeedbackItem>
          <FlexContainer alignItems='center'>
            <Subtitle className='Subtitle'>Completion rate</Subtitle>
            <ButtonInfo className='OutlineLg ButtonInfoDrop'>
              <div className='DropInfo DropInfoCenter pointer-events-none DropInfoRightLaptop Small'>
                Percentage of completed surveys out of total sent surveys
              </div>
            </ButtonInfo>
          </FlexContainer>
          <Body0 className='Body0'>
            {Number.isFinite(100 / (Views / CompletedSurveys))
              ? (100 / (Views / CompletedSurveys)).toFixed(0)
              : 0}
            %
          </Body0>
        </FeedbackItem>

        <FeedbackItem>
          <FlexContainer alignItems='center'>
            <Subtitle className='Subtitle'>GMB Positive Review Clicks</Subtitle>
            <ButtonInfo className='OutlineLg ButtonInfoDrop'>
              <div className='DropInfo DropInfoCenter DropInfoCenterMob pointer-events-none DropInfoRightLaptop Small'>
                Number of customers linked to your Google Review page after
                completing the survey. Positive reviews on Google will help
                drive visitors to your store, bring you more online orders and
                boost your search rankings.
              </div>
            </ButtonInfo>
          </FlexContainer>
          <Body0 className='Body0'>{GMBReviewClicks || 0}</Body0>
        </FeedbackItem>
        {(tabIndex === 0 || tabIndex === 2) && (
          <FeedbackItem>
            <FlexContainer alignItems='center'>
              <Subtitle className='Subtitle'>Restaurant was closed</Subtitle>
              <ButtonInfo className='OutlineLg ButtonInfoDrop'>
                <div className='DropInfo DropInfoCenter pointer-events-none DropInfoRightLaptop Small'>
                  Number of customers who came to the restaurant to pick up
                  their order and the restaurant was closed
                </div>
              </ButtonInfo>
            </FlexContainer>
            <Body0 className='Body0'>{displayStatsRestaurantClosed()}</Body0>
          </FeedbackItem>
        )}
      </FeedbackItems>
      <DashboardFields className='DashboardFields marginMinus DashboardFieldsCustomerFeedback'>
        <DashboardField className='wAutoSpace dFlex DashboardFieldCustomerFeedback'>
          <DashboardFieldLabel className='DashboardFieldLabel widthAuto'>
            <FieldLabel className='FieldLabelLg'>Time period</FieldLabel>
          </DashboardFieldLabel>
          <DashboardFieldInner className='widthWithoutLabel'>
            <DatepickerModule
              date={[datePickerStartDate, datePickerEndDate]}
              updateDate={updateDate}
              defaultSelectIndex={datePickerSelectIndex}
              updateSelectedDateIndex={updateSelectedDateIndex}
            />
          </DashboardFieldInner>
        </DashboardField>
      </DashboardFields>
    </FeedbackPanelContainer>
  );
};

export default FeedbackPanel;
