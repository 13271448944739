import styled from 'styled-components';

export const FindDeliveryContainer = styled.div`
  .FindDeliverySearch {
    .DashboardFields {
      @media (max-width: 720px) {
        display: block;
        .wAuto {
          padding-top: 0;
        }
      }
    }
  }
`;

export const FindDeliveryHeaderItems = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -20px;
  @media (max-width: 1023px) {
    margin: 0 -10px;
  }
`;

export const FindDeliveryHeaderItem = styled.div`
  flex: 1 1 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
  max-width: 25%;
  @media (max-width: 1023px) {
    padding: 0 10px;
  }
  &::after {
    top: 0;
    right: 0;
    content: '';
    position: absolute;
    width: 1px;
    height: 54px;
    background-color: #dfe2ea;
  }
  &:nth-last-child(1) {
    &::after {
      display: none;
    }
  }

  &.Buttons {
    width: 22%;
  }
  .FindDeliveryHeaderTitle {
    @media (max-width: 1023px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .FindDeliveryHeaderValue {
    @media (max-width: 1023px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .FindDeliveryHeaderButtons {
    display: flex;
    button:first-child {
      margin-right: 20px;
    }
  }
`;

export const FindDeliveryResultContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin-top: 18px;
  padding-left: 10px;
`;

export const FindDeliveryResultItems = styled.div`
  border-bottom: 2px solid #d5d8de;
  &:last-child {
    border-bottom: 0;
  }
  &.noBorder {
    border-bottom: 0;
  }
`;

export const FindDeliveryResultItem = styled.div`
  display: flex;
  align-items: baseline;
  &:last-child {
    margin-bottom: 0;
  }

  .list-title {
    margin-bottom: 7px;
    width: 50%;
  }

  .listPoint {
    display: list-item;
    list-style-type: disc;
    margin-left: 30px;
  }

  &.list-title2 {
    display: list-item;
    list-style-type: disc;
    margin-bottom: 7px;
    width: ${(props) => {
      if (props.modifierLevel) {
        return `${300 - 20 * (props.modifierLevel - 1)}px`;
      }
      return '300px';
    }};

    &.minusWidth {
      width: ${(props) => {
        if (props.modifierLevel) {
          return `${280 - 20 * (props.modifierLevel - 1)}px`;
        }
        return '280px';
      }};
    }
    &::marker {
      color: #ff2d38;
    }
  }
`;

export const FindDeliveryResultItemRside = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding-left: 10px;

  .rightBlock {
    // width: 60px;
    text-align: left;
  }
`;
