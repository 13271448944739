import {takeEvery, put, select} from 'redux-saga/effects';
import moment from 'moment';
import {getFeedbackStatsApi} from '../../helpers/services/api/feedback';
import {
  GET_FEEDBACK_DATA,
  GET_FEEDBACK_DATA_SUCCESS,
  SET_LOADING
} from './action';
import {feedbackType} from '../../routes/App/feedback/constants';

function* getLocationFeedbackStats() {
  try {
    yield put(SET_LOADING(true));

    const {
      feedbackReducer: {
        filters: {tabIndex, startDate, endDate}
      },
      user: {
        activeLocation: {Timezone}
      }
    } = yield select((state) => state);

    const locationDate = () => {
      return moment().utcOffset(Timezone, false).utc();
    };

    const serverStartDate =
      startDate || locationDate().subtract(40, 'year').startOf('day').format();
    const serverEndDate = endDate || locationDate().endOf('isoWeek').format();

    const {data} = yield getFeedbackStatsApi(
      feedbackType[tabIndex],
      serverStartDate,
      serverEndDate
    );
    if (data) {
      yield put(GET_FEEDBACK_DATA_SUCCESS(data));
    }
    yield put(SET_LOADING(false));
  } catch (error) {
    yield put(SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* feedbackSaga() {
  yield takeEvery(GET_FEEDBACK_DATA, getLocationFeedbackStats);
}

export default feedbackSaga;
