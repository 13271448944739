import React, {useEffect, useState} from 'react';
import moment from 'moment/moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import cn from 'classnames';
import {toast} from 'react-toastify';
import Statistics from '../components/Statistics';
import Headline from '../../../../components/Headline/styles';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import SoonContainer from '../components/Activate/SoonContainer';
import {DisableContainer, FeedbackInner, UnderlineButton} from '../styles';
import ActivateContainer from '../components/Activate/ActivateContainer';
import {DashboardHeaderActions} from '../../../../components/DashboardHeaderActions/styles';
import PrimaryLinkDefault from '../../../../components/buttons/PrimaryLinkDefault/styles';
import IconPickupSurvey from '../../../../assets/IconPickupSurvey';
import IconDeliveryCar from '../../../../assets/IconDeliveryCar';
import {CLEAR_FEEDBACK_DATA} from '../../../../store/feedback/action';
import {ButtonPrimary} from '../../../../components/buttons';
import IconDisableX from '../../../../assets/IconDisableX';
import {
  SET_FEEDBACK_BANNER_STATUS,
  SET_FEEDBACK_STATUS,
  SET_FEEDBACK_STATUS_SUCCESS
} from '../../../../store/global/user/action';
import Body3 from '../../../../components/typography/Body3';
import IconDisableSurvey from '../../../../assets/IconDisableSurvey';
import DialogDisableSurvey from '../components/modals/DisableSurvey';
import FeedbackBanner from '../components/FeedbackBanner';
import {IconViewReport} from '../../../../assets/IconViewReport';

const FeedbackContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenDisableModal, setIsOpenDisableModal] = useState(false);
  const {
    activeLocation: {
      hasReceivedSurveys,
      hasClosedSurveyBanner,
      isSurveyActivated
    }
  } = useSelector((state) => state.user);
  const tabIndex = useSelector(
    (state) => state.feedbackReducer.filters.tabIndex
  );
  const Timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const showPublishProgressBar = useSelector(
    (state) => state.globalNotificationsReducer.showPublishProgress
  );
  const showUnPublishBanner = useSelector(
    (state) => state.globalNotificationsReducer.unPublishBanner.showBanner
  );
  const datePickerSelectIndex = useSelector(
    (state) => state.feedbackReducer.filters.datePickerSelectIndex
  );
  const startDate = useSelector(
    (state) => state.feedbackReducer.filters.startDate
  );
  const endDate = useSelector((state) => state.feedbackReducer.filters.endDate);

  const [isBannerVisible, setIsBannerVisible] = useState(
    !hasClosedSurveyBanner
  );

  const locationDate = () => {
    return moment().utcOffset(Timezone, false).utc();
  };

  const enableSurvey = () => {
    dispatch(
      SET_FEEDBACK_STATUS({
        isSurveyActivated: true,
        onSuccess: () => {
          dispatch(SET_FEEDBACK_STATUS_SUCCESS(true));
          toast.success('Survey has been enabled successfully!');
        },
        onError: () => {
          toast.error('Something went wrong! Please try again later!');
        }
      })
    );
  };

  useEffect(() => {
    return () => dispatch(CLEAR_FEEDBACK_DATA());
  }, []);

  useEffect(() => {
    setIsBannerVisible(!hasClosedSurveyBanner);
  }, [hasClosedSurveyBanner]);

  return (
    <>
      {isBannerVisible && isSurveyActivated && (
        <FeedbackBanner
          onClose={() => {
            dispatch(
              SET_FEEDBACK_BANNER_STATUS({
                hasClosedSurveyBanner: true,
                onSuccess: () => {
                  setIsBannerVisible(false);
                },
                onError: () => {
                  setIsBannerVisible(true);
                }
              })
            );
          }}
        />
      )}
      <DashboardContent
        className={cn('DashboardContent', {
          disabledSurvey:
            !isSurveyActivated ||
            (isBannerVisible && !showUnPublishBanner && !showPublishProgressBar)
        })}
      >
        {!isSurveyActivated && hasReceivedSurveys && (
          <DisableContainer>
            <IconDisableSurvey />
            <Body3 className='White'>
              Your Feedback survey is currently disabled, new data is not coming
              in.
              <UnderlineButton onClick={() => enableSurvey()}>
                Click to enable
              </UnderlineButton>
            </Body3>
          </DisableContainer>
        )}
        <DashboardHeader>
          <Headline>Customer Feedback</Headline>
          <DashboardHeaderActions>
            {hasReceivedSurveys && (
              <>
                {tabIndex === 2 && (
                  <PrimaryLinkDefault
                    target='_blank'
                    href={`${process.env.REACT_APP_SURVEY_PAGES_URL}/pickup/demo/demo`}
                    className='ButtonMedium ButtonSurvey'
                  >
                    <IconPickupSurvey />
                    <span>View pickup survey</span>
                  </PrimaryLinkDefault>
                )}
                {tabIndex === 1 && (
                  <PrimaryLinkDefault
                    target='_blank'
                    href={`${process.env.REACT_APP_SURVEY_PAGES_URL}/delivery/demo/demo`}
                    className='ButtonMedium ButtonSurvey'
                  >
                    <IconDeliveryCar />
                    <span>View delivery survey</span>
                  </PrimaryLinkDefault>
                )}
              </>
            )}

            {hasReceivedSurveys && (
              <ButtonPrimary
                className='ButtonMedium ButtonSurvey'
                onClick={() => {
                  history.push('/reports', {
                    fromFeedback: true,
                    datePickerConfig: {
                      startDate: startDate
                        ? moment(startDate)
                            .utcOffset(Timezone, false)
                            .utc()
                            .format('YYYY-MM-DD')
                        : locationDate()
                            .subtract(40, 'year')
                            .startOf('day')
                            .format('YYYY-MM-DD'),
                      endDate: endDate
                        ? moment(endDate)
                            .utcOffset(Timezone, false)
                            .utc()
                            .format('YYYY-MM-DD')
                        : locationDate().endOf('isoWeek').format('YYYY-MM-DD'),
                      datePickerSelectIndex
                    }
                  });
                }}
              >
                <IconViewReport />
                <span>View report</span>
              </ButtonPrimary>
            )}
            {isSurveyActivated && hasReceivedSurveys && (
              <ButtonPrimary
                className='ButtonMedium ButtonSurvey'
                onClick={() => setIsOpenDisableModal(true)}
              >
                <IconDisableX />
                <span>Disable survey</span>
              </ButtonPrimary>
            )}
          </DashboardHeaderActions>
        </DashboardHeader>
        {hasReceivedSurveys && <Statistics />}
        {!isSurveyActivated && !hasReceivedSurveys && (
          <FeedbackInner>
            <ActivateContainer />
          </FeedbackInner>
        )}
        {isSurveyActivated && !hasReceivedSurveys && (
          <FeedbackInner>
            <SoonContainer />
          </FeedbackInner>
        )}
        <DialogDisableSurvey
          isOpenDisableModal={isOpenDisableModal}
          setIsOpenDisableModal={setIsOpenDisableModal}
          Title='Disable Feedback Survey'
          Description='New customer’s feedback will stop arriving, however existing data will not be erased. You could
              always turn it on again whenever you wish.'
        />
      </DashboardContent>
    </>
  );
};

export default FeedbackContainer;
